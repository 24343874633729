import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import ButtonBack from "../../../../components/Basic/Simple/Buttons/ButtonBack";
import ButtonClear from "../../../../components/Basic/Simple/Buttons/ButtonClear";
import ButtonGroup from "../../../../components/Basic/Simple/Buttons/ButtonGroup";
import ButtonUpdate from "../../../../components/Basic/Simple/Buttons/ButtonUpdate";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import Page from "../../../../components/Basic/Mixed/Pages/Page";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionWithButtonContainer from "../../../../components/Basic/Mixed/Sections/SectionWithButtonContainer";
import getStructure, { toSubmitData } from "./DevelopersStructure";
import useFormTable from "src/hooks/useFormTable";
import useOnFormSubmit from "../../../../hooks/useOnFormSubmit";
import { MUIDataTableColumnDef } from "mui-datatables";
import { ReturnUser } from "../../../../accurasee-backend-types/app/user/user.types";
import { useSelector } from "react-redux";
import { useMUIDataTableOptions } from "src/hooks/useMUIDataTableOptions";
import {
  useGetAllUsersQuery,
  useUpdateUsersMutation,
} from "../../../../redux/services/UserService";

const Developers = () => {
  const { data: usersQuery, isLoading: isLoadingCrons } =
    useGetAllUsersQuery(undefined);
  const users = usersQuery?.data || [];
  const user = useSelector(
    (state: { user: { user: ReturnUser } }) => state.user.user,
  );
  // const { getStructure, toSubmitData } = useLanguagesStructure();

  const [updateUsers] = useUpdateUsersMutation();

  const {
    formData,
    helperText,
    isFormValid,
    setFormData,
    setHasTriedToSubmit,
    structure,
  } = useFormTable({
    initialFormData: users,
    getStructure,
  });

  const submitData = toSubmitData(formData, users);

  const { onFormSubmit, refForm, isSubmitting } = useOnFormSubmit({
    submitProps: {
      apiMutations: {
        update: updateUsers,
      },
      data: { update: submitData }, // Remove unchanged, deleted key-value(s) and added blank row
      name: "User",
    },
    onSuccess: () => {
      for (const d of submitData) {
        if (d._id === user._id) {
          window.location.reload();
        }
      }

      setHasTriedToSubmit(false);
    },
  });

  const { options } = useMUIDataTableOptions({
    pagination: formData.length >= 10,
  });

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle({
      data: formData,
      helperText,
      item,
    }),
  );

  return (
    <Page
      label={"Developers"}
      subLabel={"Page for managing developer settings"}
    >
      <form
        ref={refForm}
        onSubmit={async (e) => {
          e.preventDefault();
          await onFormSubmit();
        }}
      >
        <SectionContainer isLoading={isLoadingCrons}>
          <MuiDataTableWrapper
            data={formData}
            columns={columns}
            options={options}
            tableType="main-with-filter"
            noBackground={true}
          />
        </SectionContainer>
      </form>
      <SectionWithButtonContainer>
        <ButtonBack />
        <ButtonGroup>
          <ButtonClear
            onClick={() => {
              if (users) {
                setFormData(users);
                setHasTriedToSubmit(false);
              }
            }}
          />
          <ButtonUpdate
            initialSubmitData={users}
            isSubmitting={isSubmitting}
            isValid={isFormValid}
            onSubmit={() => {
              refForm.current.requestSubmit();
              setHasTriedToSubmit(true);
            }}
            submitData={formData}
          />
        </ButtonGroup>
      </SectionWithButtonContainer>
    </Page>
  );
};

export default Developers;
