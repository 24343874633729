/**
 *Created by Mikael Lindahl on 2024-03-19
 */

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import ErrorWithStack from "src/components/Basic/Simple/Notifications/ErrorWithStack";
import Grid from "@mui/material/Grid";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import MemoryUsageChart from "src/components/Basic/Mixed/Charts/MemoryUsageChart";
import integrationImportStepTypeToReadable from "src/accurasee-backend-types/shared/utils/integrationImportStepTypeToReadable";
import useIntegrationImportSetups from "../Hooks/useIntegrationImportSetups";
import useTranslation from "src/hooks/useTranslationWrapper";
import { Box, CircularProgress, Typography } from "@mui/material";
import { DeveloperState } from "src/redux/slices/developerState";
import { IntegrationImportJob } from "src/accurasee-backend-types/app/integration_import_job/integration_import_job.types";
import { RootState } from "src/redux/reducers";
import { useSelector } from "react-redux";

type IntegrationImportJobComponentTProps = {
  job: IntegrationImportJob;
};

function formatTime(seconds: number): string {
  const days = Math.floor(seconds / 86400);
  seconds %= 86400;
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;

  let result = "";
  if (days > 0) result += `${days} d `;
  if (hours > 0) result += `${hours} h `;
  if (minutes > 0) result += `${minutes} min `;
  if (seconds > 0) result += `${Math.ceil(seconds * 10) / 10} s`;

  return result.trim();
}

const IntegrationInfo = (props: {
  children: React.ReactNode;
  italic?: boolean;
}) => (
  <Typography
    sx={{
      fontSize: "14px",
      marginBottom: 0.5,
      marginRight: 1,
      ...(props?.italic ? { fontStyle: "italic" } : {}),
    }}
  >
    {props.children}
  </Typography>
);

const IntegrationImportJobComponent = ({
  job,
}: IntegrationImportJobComponentTProps) => {
  const [t] = useTranslation();

  const developerState = useSelector<RootState, DeveloperState>(
    ({ developerState }) => developerState,
  );

  const numberOfSteps = job.steps?.length;

  const { nameTypeToPretty } = useIntegrationImportSetups();

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container columnSpacing={1}>
        <Grid item xs={3}>
          <Typography
            sx={{ fontSize: "14px", fontWeight: 600, textAlign: "right" }}
          >
            {`${t("Step")} ${nameTypeToPretty[job.groupPartImportName]?.name}`}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          {job.steps?.map((step, index) => {
            return (
              <Box key={index}>
                <Box
                  display="flex"
                  flexDirection="row"
                  flexWrap="wrap"
                  alignItems="center"
                  justifyContent="space-between"
                  key={index}
                >
                  <IntegrationInfo>
                    {`${
                      numberOfSteps === 1 ? "" : `${t("Step")} ${index + 1}:`
                    } ${integrationImportStepTypeToReadable[step.type]}`}
                  </IntegrationInfo>
                  <Box display="flex" flexDirection="row">
                    {step.description !== undefined && (
                      <IntegrationInfo>{t(step.description)}</IntegrationInfo>
                    )}
                    {(step.status === "pending" ||
                      step.status === "running" ||
                      step.status === "error") && (
                      <IntegrationInfo>
                        {step.progress?.totalNumberOfEntries !== undefined &&
                        step.progress?.processedNumberOfEntries !== undefined
                          ? `${t("Progress")}: ${
                              step.progress?.processedNumberOfEntries
                            } / ${step.progress?.totalNumberOfEntries}`
                          : ""}
                      </IntegrationInfo>
                    )}
                    {step.status === "pending" || step.status === "running" ? (
                      <>
                        <IntegrationInfo>
                          {step.status === "pending" && t("Pending")}
                          {step.status === "running" && t("Running")}
                        </IntegrationInfo>

                        {step.status === "pending" && (
                          <HourglassEmptyIcon
                            style={{ color: "orange" }}
                            fontSize="small"
                          />
                        )}
                        {step.status === "running" && (
                          <CircularProgress
                            color="primary"
                            size={20}
                            sx={{ padding: "var(--space-2px)" }}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {step.status === "completed" && (
                          <>
                            {step.progress?.processedNumberOfEntries !==
                              undefined && (
                              <IntegrationInfo italic>
                                {` ${
                                  step.progress?.processedNumberOfEntries
                                } ${t("entries")}`}
                              </IntegrationInfo>
                            )}
                            {step.progress?.completionTimeSeconds !==
                              undefined && (
                              <IntegrationInfo italic>
                                {`Completed in ${formatTime(
                                  step.progress.completionTimeSeconds,
                                )}`}
                              </IntegrationInfo>
                            )}
                            <CheckCircleIcon
                              style={{ color: "green" }}
                              fontSize="small"
                            />
                          </>
                        )}
                        {step.status === "error" && (
                          <ErrorIcon
                            style={{ color: "red" }}
                            fontSize="small"
                          />
                        )}
                      </>
                    )}
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                >
                  {step.status === "running" &&
                    !!step.progress?.elapsedTimeInSeconds && (
                      <Typography
                        sx={{
                          marginBottom: 1,
                          marginRight: 3,
                          fontSize: "12px",
                        }}
                      >
                        {`Elapsed time: ${formatTime(
                          step.progress.elapsedTimeInSeconds,
                        )}`}
                      </Typography>
                    )}
                  {step.status === "running" &&
                    !!step.progress?.estimatedTimeLeftInSeconds && (
                      <Typography
                        sx={{
                          marginBottom: 1,
                          marginRight: 3,
                          fontSize: "12px",
                        }}
                      >
                        {`Estimated time left: ${formatTime(
                          step.progress.estimatedTimeLeftInSeconds,
                        )}`}
                      </Typography>
                    )}
                </Box>
                {step.status === "error" && step.error && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    alignItems="center"
                    justifyContent="center"
                    key={index + "error"}
                  >
                    <ErrorWithStack error={step.error} />
                  </Box>
                )}
                {step.progress?.attachmentErrors &&
                  step.progress?.attachmentErrors.length > 0 &&
                  step.progress.attachmentErrors.map(
                    (attachmentError, index2) => (
                      <Box
                        display="flex"
                        flexDirection="row"
                        flexWrap="wrap"
                        alignItems="center"
                        justifyContent="center"
                        key={index + "-" + index2 + "-attachmentError"}
                      >
                        <ErrorWithStack error={attachmentError.error} />
                      </Box>
                    ),
                  )}
              </Box>
            );
          })}
        </Grid>
        {job.error && (
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ height: "100%" }}
            >
              <ErrorWithStack error={job.error} />
            </Box>
          </Grid>
        )}
        {developerState.debug["integration_import"] && (
          <Grid item xs={12}>
            <MemoryUsageChart data={job?.memoryUsage} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default IntegrationImportJobComponent;
