/**
 *Created by Mikael Lindahl on 2024-06-04
 */

import { CollectionName } from "../../accurasee-backend-types/app/general/collection.types";
import { getProjectPath } from "../../context/MicrosoftDriveProvider/Utils/PathHelper";
import {
  DriveItemWrapper,
  MicrosoftDriveService,
  MicrosoftTemplatePopulateData,
} from "../../context/MicrosoftDriveProvider/Lib/MicrosoftDriveService";
import { useSnackbar } from "notistack";
import { MicrosoftFileDataValue } from "./useMicrosoftDriveList";

type AddDocumentFromTemplateProps<
  DocumentType extends {},
  ExtraType extends {},
> = {
  data?: MicrosoftTemplatePopulateData;
  selectedFileName: string;
  selectedGroup: string;
  selectedTemplate: MicrosoftFileDataValue | null;
  selectedType: string;
};

const useMicrosoftDriveCreateDocumentFromTemplate = ({
  collectionName,
  leafFolder,
  service,
}: {
  collectionName: CollectionName;
  leafFolder?: string;
  service: MicrosoftDriveService | null;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const createDocumentFromTemplate = async <
    DocumentType extends {},
    ExtraType extends {},
  >({
    data,
    selectedFileName,
    selectedGroup,
    selectedTemplate,
    selectedType,
  }: AddDocumentFromTemplateProps<DocumentType, ExtraType>) => {
    if (!service || !leafFolder || !selectedTemplate) return;

    try {
      const projectPath = getProjectPath({
        collectionName,
        config: service?.config,
        leafFolder,
      });

      let generatedItem: Partial<DriveItemWrapper>;
      if (
        selectedFileName.includes(".doc") ||
        selectedFileName.includes(".docx") ||
        selectedFileName.includes(".xls") ||
        selectedFileName.includes(".xlsx")
      ) {
        // generatedItem is missing webDavUrl and webDavUrl. Need to call getFileMetadata
        // to get the missing properties and latest version
        generatedItem = await service.addDocumentToTemplate({
          template: selectedTemplate,
          fileName: selectedFileName,
          projectPath,
          data,
        });
      } else {
        const sourcePath = service.config.storageTemplateLocation;

        generatedItem = await service.copyFile({
          sourceFileName: selectedTemplate.name as string,
          sourcePath,
          targetFileName: selectedFileName,
          targetPath: projectPath,
        });
      }

      const metadata = {
        description: encodeURIComponent(
          JSON.stringify({
            group: selectedGroup,
            type: selectedType,
          }),
        ),
      };

      await service.addMetadata({ id: generatedItem.id, metadata });
      await service.addMetadata({ id: selectedTemplate.id, metadata });
      const finalItem = await service.getDriveItem({
        driveId: generatedItem?.parentReference?.driveId,
        id: generatedItem.id,
      });

      setTimeout(() => service.openURL(finalItem), 1000);
    } catch (e) {
      console.error("Error", e);
      enqueueSnackbar(
        "An error occurred while creating the document from the template.",
        { variant: "error" },
      );
      // setIsError(true);
    }
  };

  return { createDocumentFromTemplate };
};

export default useMicrosoftDriveCreateDocumentFromTemplate;
