import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import { Box, Typography } from "@mui/material";
import {
  NameType,
  Payload,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { getYearStructure } from "./DateRangeStructure";
import { normalizedYearResultData } from "./utils";
import { RootState } from "src/redux/reducers";
import { TFunction } from "src/hooks/useTranslationWrapper";
import { useGetReportTemplateYFiltersQuery } from "src/redux/services/ReportTemplateFilterService";
import { useGetYearToDateResultQuery } from "src/redux/services/InsightService";
import { useSelector } from "react-redux";
import BuildItems from "src/components/Builders/Container/BuildItems";
import Constants from "src/constants/Constants";
import Section from "src/components/Basic/Simple/Sections/Section";
import toDay from "src/utils/date";
import useFormContainer from "src/hooks/useFormContainer";
import useTranslation from "src/hooks/useTranslationWrapper";

const CustomizedAxisTick = ({
  x,
  y,
  payload,
  isRotated,
}: {
  x?: number;
  y?: number;
  payload?: Payload<ValueType, NameType>;
  isRotated?: boolean;
}) => (
  <g transform={`translate(${x},${y})`} overflow="visible">
    <text
      x={0}
      y={0}
      dy={16}
      textAnchor={`${isRotated ? "end" : "middle"}`}
      fill="#666"
      fontSize={`${isRotated ? 8 : 12}`}
      transform={`${isRotated ? "rotate(-20)" : "rotate(0)"}`}
    >
      {payload?.value}
    </text>
  </g>
);

const CustomizedLabel = (props: {
  x?: number;
  y?: number;
  fill?: string;
  width?: number;
  height?: number;
  value?: number;
}) => {
  const { x, y, fill, width, height, value } = props;
  // radius helps the view label fully
  const radius = 10;
  return (
    <g>
      <text
        x={x && width ? x + width / 2 : 0}
        y={height && y ? (height > 0 ? y - radius : y + height - radius) : 0}
        fill={fill || "var(--green-2)"}
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value}
      </text>
    </g>
  );
};

const CustomTooltip = ({
  active,
  payload,
  t,
}: TooltipProps<ValueType, NameType> & { t?: TFunction }) => {
  if (active && payload && payload.length) {
    const { fill, label, value } = payload[0].payload;
    const type = value[0] < value[1] ? "Increase" : "Decrease";
    return (
      <Box
        sx={{
          backgroundColor: "var(--white-primary)",
          borderRadius: "8px",
          boxShadow: "0px 5px 13px var(----black-2)",
          padding: "var(--space-8px) var(--space-16px)",
          opacity: 0.9,
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            marginBottom: "var(--space-8px)",
            color: "var(--black-7)",
          }}
        >
          {t ? t(label) : label}
        </Typography>
        {value[1] === 0 ? (
          // Net income
          <Typography sx={{ color: fill }}>
            {`${t ? t("Value") : "Value"}: ${value[0]}`}
          </Typography>
        ) : (
          <Typography sx={{ color: fill }}>
            {`${t ? t(type) : type}: ${value[0]} ~ ${value[1]}`}
          </Typography>
        )}
      </Box>
    );
  }

  return null;
};

const ResultYearToDate = ({ contractId }: { contractId?: string }) => {
  const [t] = useTranslation();

  const {
    data: reportFiltersQuery,
    isLoading: isAllReportTemplateYFiltersLoading,
  } = useGetReportTemplateYFiltersQuery(undefined);
  const allReportTemplateYFilters = reportFiltersQuery?.data || [];

  const hasAccountNumberSeries =
    allReportTemplateYFilters
      ?.map((filter) => filter?.accountNumberSeries)
      ?.reduce((acc, val) => acc.concat(val), [])?.length > 0;

  const currentYear = new Date().getFullYear();

  const { activeCompanyId } = useSelector<RootState, any>(({ user }) => {
    return user.user;
  });

  const { formData, helperText, structure } = useFormContainer<{
    year: Date;
  }>({
    getStructure: getYearStructure,
    initialFormData: {
      // Default date is the current year from start to today
      year: new Date(currentYear, 0, 1),
    },
  });
  const filter: any = {
    fromDate: toDay(formData?.year),
    toDate:
      formData?.year && new Date(formData.year).getFullYear() === currentYear
        ? toDay(new Date())
        : toDay(new Date(formData?.year.getFullYear() || currentYear, 11, 31)),
    companyId: activeCompanyId,
  };
  if (contractId) {
    filter.contractId = contractId;
  }
  const { data: queryResult, isLoading } = useGetYearToDateResultQuery(filter);

  const commonPropsBuildItem = {
    data: formData,
    helperText,
    disabled: !hasAccountNumberSeries,
  };

  const rawData = queryResult || [];
  const data = normalizedYearResultData({
    rawData,
    increaseColor: "var(--information-5)",
    decreaseColor: "var(--negative-2)",
    totalColor: "var(--green-2)",
  });

  return (
    <Section
      label="Result year to date"
      rightBoxArea={BuildItems({
        items: structure.items,
        ...commonPropsBuildItem,
      })}
      rightBoxAreaSx={{
        display: "flex",
        columnGap: "var(--space-8px)",
        marginBottom: "var(--space-12px)",
      }}
      isLoading={isLoading || isAllReportTemplateYFiltersLoading}
    >
      {!hasAccountNumberSeries ? (
        <Box
          sx={{
            fontSize: 12,
            fontWeight: "bold",
            color: "var(--negative-1)",
            margin: "auto",
          }}
        >
          {t(
            "This company is missing income or cost number series, therefore categorised incomes and costs will not be displayed.",
          )}
        </Box>
      ) : (
        <></>
      )}
      <ResponsiveContainer width="100%" height={500}>
        <BarChart
          width={1000}
          height={500}
          data={data}
          margin={{ top: 10, right: 20, left: 20, bottom: 10 }}
          barCategoryGap={0}
          {...{ overflow: "visible" }}
        >
          {hasAccountNumberSeries ? (
            <>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                interval={0}
                dataKey="label"
                tick={
                  <CustomizedAxisTick
                    isRotated={data.length > Constants.AXIS_TICK_LIMIT}
                  />
                }
              />
              <YAxis />
              <Tooltip content={<CustomTooltip t={t} />} />
              <Legend
                wrapperStyle={{
                  bottom: "-32px",
                  left: "-24px",
                  backgroundColor: "var(--white-primary)",
                  width: "calc(100% + 64px)",
                }}
              />
              <Bar
                name={t("Increase")}
                dataKey="value"
                fill="var(--information-5)"
                maxBarSize={0}
              />
              <Bar
                name={t("Decrease")}
                dataKey="value"
                fill="var(--negative-2)"
                maxBarSize={100}
              >
                <LabelList dataKey="diff" content={<CustomizedLabel />} />
                {data.map((entry: any, index: number) => (
                  <Cell key={`cell-${index}`} fill={entry?.fill} />
                ))}
              </Bar>
              <Bar
                name={t("Net income")}
                dataKey="value"
                fill="var(--green-2)"
                maxBarSize={0}
              />
            </>
          ) : (
            <>
              <CartesianGrid
                strokeDasharray="3 3"
                // set background color for Chart
                fill="var(--gray-10)"
              />
              <XAxis interval={0} dataKey="label" />
              <Legend
                wrapperStyle={{
                  bottom: "-32px",
                  left: "-24px",
                  backgroundColor: "var(--white-primary)",
                  width: "calc(100% + 64px)",
                }}
              />
              <Bar
                name={t("Increase")}
                dataKey=""
                fill="var(--information-5)"
              />
              <Bar name={t("Decrease")} dataKey="" fill="var(--negative-2)" />
              <Bar name={t("Net income")} dataKey="" fill="var(--green-2)" />
            </>
          )}
        </BarChart>
      </ResponsiveContainer>
    </Section>
  );
};

export default ResultYearToDate;
