import Page from "src/components/Basic/Mixed/Pages/Page";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import ProjectTrackerRegionTable from "./components/ProjectTrackerRegionTable";
import {
  projectTrackerConsolidateCompanyIncomeReportMockData,
  projectTrackerPercentageOfCompletionData,
  projectTrackerPercentageOfCompletionSubData,
  projectTrackerPercentageOfCompletionVariant2Data,
  projectTrackerPercentageOfCompletionVariant2SubData,
  projectTrackerRegionData,
} from "./resources/projectTrackerMockData";
import Tabs from "../../../components/Basic/Mixed/Tabs/Tabs";
import ProjectTrackerPercentageOfCompletionTable from "./components/ProjectTrackerPercentageOfCompletionTable";
import ProjectTrackerPercentageOfCompletionVariant2Table from "./components/ProjectTrackerPercentageOfCompletionVariant2Table";
import ProjectTrackerConsolidatedCompanyIncomeReportTable from "./components/ProjectTrackerConsolidatedCompanyIncomeReportTable";

const TABS = [
  "Regional overview",
  "Percentage of completion",
  "POC demo company",
  "Consolidated company income report",
];

const ProjectTrackerRegion = () => {
  return (
    <Page label={"Project tracker"}>
      <Tabs name={"project-tracker"} tabs={TABS}>
        <SectionContainer>
          <ProjectTrackerRegionTable data={projectTrackerRegionData} />
        </SectionContainer>
        <SectionContainer>
          <ProjectTrackerPercentageOfCompletionTable
            data={projectTrackerPercentageOfCompletionData}
            dataSub={projectTrackerPercentageOfCompletionSubData}
          />
        </SectionContainer>
        <SectionContainer>
          <ProjectTrackerPercentageOfCompletionVariant2Table
            data={projectTrackerPercentageOfCompletionVariant2Data}
            dataSub={projectTrackerPercentageOfCompletionVariant2SubData}
          />
        </SectionContainer>
        <ProjectTrackerConsolidatedCompanyIncomeReportTable
          data={projectTrackerConsolidateCompanyIncomeReportMockData}
        />
      </Tabs>
    </Page>
  );
};

export default ProjectTrackerRegion;
