/**
 *Created by Mikael Lindahl on 2022-12-13
 */

import Box from "@mui/material/Box";
import { forwardRef } from "react";
import { SxProps } from "@mui/material";
import BoxHeadingLabel from "./BoxHeadingLabel";
import BoxHeadingSubLabel, { SubLabelType } from "./BoxHeadingSubLabel";
import { useTheme } from "@mui/material/styles";

export type BoxHeadingTProps = {
  alignTextCenter?: boolean;
  forModal?: boolean;
  label?: string;
  labelBadge?: JSX.Element;
  rightBoxArea?: JSX.Element | JSX.Element[];
  rightBoxAreaSx?: SxProps;
  subLabel?: string;
  subLabelType?: SubLabelType;
  sx?: SxProps;
  withTightTableHeading?: boolean;
};

const BoxHeading = forwardRef((props: BoxHeadingTProps, ref) => {
  const theme = useTheme();

  return props.label || props.subLabel || props.rightBoxArea ? (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: props.alignTextCenter ? "center" : "space-between",
        alignItems: "center",
        columnGap: "1rem",
        padding: "1rem 0 1rem 0",
        ...(props.withTightTableHeading
          ? {
              // paddingTop: "1.5rem",
              [theme.breakpoints.up("lg")]: {
                position: "absolute",
                width: "fit-content",
              },
            }
          : {}),
        ...props.sx,
      }}
    >
      {(props.label || props.subLabel) && (
        <Box
          sx={{
            display: "inline-block",
            height:
              props.forModal && props.subLabel
                ? "3rem !important"
                : props.forModal
                  ? "2rem !important"
                  : "3rem !important",
            textAlign: props.alignTextCenter ? "center" : "left",
          }}
        >
          <BoxHeadingLabel label={props.label} labelBadge={props.labelBadge} />
          <BoxHeadingSubLabel
            subLabel={props.subLabel}
            subLabelType={props.subLabelType}
          />
        </Box>
      )}
      {props.rightBoxArea && (
        <Box sx={{ zIndex: 2, ...props.rightBoxAreaSx }}>
          {props.rightBoxArea}
        </Box>
      )}
    </Box>
  ) : (
    <></>
  );
});

export default BoxHeading;
