/**
 *Created by Mikael Lindahl on 2024-06-07
 */

import DocumentHandler from "./Utils/DocumentHandler";
import ExcelHandler from "./Utils/ExcelHandler";
import WordHandler from "./Utils/WordHandler";
import { MicrosoftTemplatePopulateData } from "../MicrosoftDriveService";
import { getNestedObjectData } from "../../../../utils/nestedData";
import {
  MicrosoftDriveConfig,
  TemplateTagContractOfferItem,
} from "../../../../accurasee-backend-types/app/general/microsoft_drive/microsoft_drive.types";

class MicrosoftDocTemplateProcessor {
  private readonly content: string;
  private readonly contentType: string;
  private readonly data: MicrosoftTemplatePopulateData | undefined;
  private readonly template: MicrosoftDriveConfig["template"];

  constructor({
    content,
    contentType,
    data,
    template,
  }: {
    content: string;
    contentType: string;
    data: MicrosoftTemplatePopulateData | undefined;
    template: MicrosoftDriveConfig["template"];
  }) {
    this.content = content;
    this.contentType = contentType;
    this.data = data;
    this.template = template;
  }

  private getValue(t: TemplateTagContractOfferItem, o: any) {
    return String(
      o && typeof t?.property === "string"
        ? getNestedObjectData({ data: o, key: t.property })
        : typeof t?.property === "function"
          ? t.property(o)
          : "missing " + t.description,
    );
  }

  async generateDocument(): Promise<any> {
    const populatedData = this.populateData();

    let doc: DocumentHandler;
    if (
      this.contentType === "application/vnd.ms-excel" ||
      this.contentType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      doc = new ExcelHandler();
    } else {
      doc = new WordHandler();
    }

    const docGenerated = await doc.generateDocument(
      this.content,
      populatedData,
    );

    return docGenerated;

    // return doc.generateDocument(this.content, populatedData);
  }

  populateData(): any {
    if (!this.template) {
      throw "Missing template";
    }
    if (!this.data) {
      throw "Missing template data";
    }

    const { type, contractOfferPopulated, user } = this.data;

    const filledData: any = {};
    switch (type) {
      case "contract_offer":
        const templateTags = this.template.contractOffer;
        for (const templateTag of templateTags) {
          // const strippedTagKey = tagKey.substring(1).slice(0, -1);

          if (!templateTag.property) continue;

          switch (templateTag.source) {
            case "callback":
              filledData[templateTag.key] = templateTag.property();
              break;
            case "contract_offer_populated":
              filledData[templateTag.key] = this.getValue(
                templateTag,
                contractOfferPopulated,
              );
              break;
            case "user":
              filledData[templateTag.key] = this.getValue(templateTag, user);
              break;
          }
        }
    }

    return {
      ...filledData,
    };
  }
}

export default MicrosoftDocTemplateProcessor;
