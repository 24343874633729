import { ImportabledocumentTypes } from "../integrations/importabledocument.types";
import { Types } from "mongoose";
import { CompanydataTypes } from "../general/companydata.types";
import { EditRights } from "../general/editrights.types";

/**
 * @param active 3L: assumed true
 * @param address1
 * @param address2
 * @param city
 * @param contact 3L: missing
 * @param country
 * @param countryCode
 * @param number
 * @param deliveryAddress1 3L: missing
 * @param deliveryCity 3L: missing
 * @param deliveryZipCode 3L: missing
 * @param emailInvoice 3L: missing
 * @param identityNumber 3L: missing
 * @param invoiceType 3L: missing
 * @param name
 * @param notes 3L: missing
 * @param organisationNumber
 * @param phone1 3L: missing
 * @param www 3L: missing
 * @param zipCode
 * @param reversedConstructionVAT 3L: assumed false. Holup! Fix this
 * @param customerType
 * @param invoiceDeliveryMethod
 * @param priceListId 3L: missing
 * @param priceListExternalId 3L: missing
 * @param termsOfPaymentExternalId 3L: missing
 * @param vatNumber
 */
interface Customer extends ImportabledocumentTypes, CompanydataTypes {
  _id?: Types.ObjectId;
  active: boolean;
  address1: string;
  address2: string | null;
  cinOfHa?: string;
  city: string;
  country: string;
  countryCode: string;
  currencyCode: string;
  customerType: CustomerType;
  defaultDiscount?: number;
  deliveryAddress1: string | null;
  deliveryCity: string | null;
  deliveryZipCode: string | null;
  emailInvoice: string;
  identityNumber: string;
  invoiceDeliveryMethod: InvoiceDeliveryMethod;
  invoiceType: string;
  name: string;
  nameOfProperty?: string;
  notes: string | null;
  organisationNumber: string;
  ourReference?: string;
  phone1: string;
  priceListExternalId: string;
  priceListId: Types.ObjectId;
  reversedConstructionVAT: boolean;
  termsOfPaymentExternalId: string;
  termsOfPaymentId: Types.ObjectId;
  vatNumber?: string;
  www: string | null;
  yourReference?: string;
  zipCode: string;
}

export enum CustomerType {
  Company = "COMPANY",
  Private = "PRIVATE",
}

enum InvoiceDeliveryMethod {
  Email,
  Letter,
}

export type CustomerWithPermissions = Customer & EditRights;

export type CustomerWithTotals = CustomerWithPermissions & {
  totalEstSalesThisYear: number;
  numberOfActiveContracts: number;
};

export type { Customer };
export { InvoiceDeliveryMethod };
