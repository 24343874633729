/**
 *Created by Mikael Lindahl on 2024-06-27
 */
import groupIntegrationJobs from "../Utils/groupIntegrationJobs";
import { DeveloperState } from "src/redux/slices/developerState";
import { integrationImportJobTestData } from "src/accurasee-backend-types/app/integration_import_job/integration_import_job.types.testData";
import { useGetIntegrationImportJobGroupsQuery } from "src/redux/services/IntegrationImportJobService";
import clone from "src/utils/clone";
import usePagination from "src/hooks/usePagination";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/reducers";
import { IntegrationImportGroup as IntegrationImportGroupType } from "../../../../../accurasee-backend-types/app/integration_import_job/integration_import_job.types";
import { useWebsocket } from "../../../../../context/WebsocketProvider";
import sort from "../../../../../utils/sort";

function useGroupedJobs() {
  const pageSizeOptions = [10, 25, 50, 100];
  const pagination = usePagination({
    defaultPageSize: pageSizeOptions[0],
    defaultPage: 0,
  });
  const developerState = useSelector<RootState, DeveloperState>(
    ({ developerState }) => developerState,
  );

  const [isShowOnlyErrors, setIsShowOnlyErrors] = useState(false);

  const {
    // integrationImportJobs: integrationImportJobsFromWebsocket,
    latestIntegrationImportJobsByGroupId,
  } = useWebsocket();

  const {
    data: dataQueryCompletedAndError,
    isLoading: isLoadingCompletedAndError,
  } = useGetIntegrationImportJobGroupsQuery({
    groupPosition: JSON.stringify({
      filterDataType: "number",
      value: 0,
    }),
    page: pagination.page,
    pageSize: pagination.pageSize,
    ...(isShowOnlyErrors
      ? { groupStatus: "error" }
      : { groupStatus: ["completed", "error"] }),
  });

  const {
    data: dataQueryRunningAndPending,
    isLoading: isLoadingRunningAndPending,
  } = useGetIntegrationImportJobGroupsQuery({
    groupPosition: JSON.stringify({
      filterDataType: "number",
      value: 0,
    }),
    groupStatus: ["running", "pending"],
  });

  const countCompletedAndError = dataQueryCompletedAndError?.metadata?.count;

  let groupedCompletedAndError = clone(dataQueryCompletedAndError?.data || []);

  let groupedPendingAndRunning = clone(dataQueryRunningAndPending?.data || []);

  const showTestData =
    developerState?.showTestData &&
    !!developerState.showTestData["integration_import"];
  const groupedTestJobs = groupIntegrationJobs(integrationImportJobTestData);

  groupedCompletedAndError = [
    ...groupedCompletedAndError,
    ...(showTestData
      ? groupedTestJobs.filter(
          (job) => job.status === "completed" || job.status === "error",
        )
      : []),
  ];

  groupedPendingAndRunning = [
    ...groupedPendingAndRunning,
    ...(showTestData
      ? groupedTestJobs.filter(
          (j) => j.status === "running" || j.status === "pending",
        )
      : []),
  ];

  // Add broadcast jobs that is missing in pending and running
  for (const groupId in latestIntegrationImportJobsByGroupId) {
    if (!groupedPendingAndRunning.find((j) => String(j.groupId) === groupId)) {
      groupedPendingAndRunning.push(
        latestIntegrationImportJobsByGroupId[groupId],
      );
    }
  }

  // Add on group properties for displaying changes in minimized view
  for (const group of [
    ...groupedCompletedAndError,
    ...groupedPendingAndRunning,
  ]) {
    const latest = latestIntegrationImportJobsByGroupId[String(group.groupId)];

    if (latest && group && latest.groupId === group.groupId) {
      group.groupStatus = latest.groupStatus;
      group.retryCount = latest.retryCount;
      group.isRetry = latest.isRetry;
    }
  }

  // Move completed or error jobs
  groupedPendingAndRunning = groupedPendingAndRunning.reduce((acc, group) => {
    const presentGroupIds = groupedCompletedAndError.map((g) => g.groupId);

    if (group.groupStatus === "completed" || group.groupStatus === "error") {
      if (!presentGroupIds.includes(group.groupId)) {
        groupedCompletedAndError = [group, ...groupedCompletedAndError];
      }
      return acc;
    }
    acc.push(group);
    return acc;
  }, [] as IntegrationImportGroupType[]);

  groupedCompletedAndError = sort(groupedCompletedAndError, (d) => d.createdAt);

  return {
    countCompletedAndError,
    developerState,
    groupedCompletedAndError,
    groupedPendingAndRunning,
    isLoadingCompletedAndError,
    isLoadingRunningAndPending,
    pagination,
    isShowOnlyErrors,
    pageSizeOptions,
    setIsShowOnlyErrors,
  };
}

export default useGroupedJobs;
