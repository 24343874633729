/**
 *Created by Mikael Lindahl on 2023-12-14
 */

import ButtonPrimary from "../../Simple/Buttons/ButtonPrimary";
import React from "react";
import YesNoDialog from "../../Simple/Dialogs/YesNoDialog";
import getHasRightFromPermissionForButtonSubmit from "src/utils/getPermissionTypeForButtonSubmit";
import isDisableSubmit from "src/utils/isDisableSubmit";
import useTranslation from "src/hooks/useTranslationWrapper";
import { ButtonCoreProps } from "../../Simple/Buttons/ButtonCore";
import { detailedDiff } from "deep-object-diff";
import _ from "lodash";
import _c from "../../../../constants/Constants";

const DEBUG =
  process.env.REACT_APP_DEBUG_BUTTON_SUBMIT_CORE === "true" || false;

export const createLabels = [
  "Add user role",
  "Create api key",
  "Create offer",
  "Create Planned invoices",
  "Create article",
  "Create contract type",
  "Create contract",
  "Create customer",
  "Create invoice plan",
  "Create index",
  "Create report",
  "create",
  "copy",
] as const;

export const updateLabels = [
  "export selected costs to invoice",
  "assign costs to selected subcontracts",
  "save & send",
  "save without sending",
  "save",
  "send",
  "update",
] as const;

export const deleteLabels = ["delete"] as const;

export type CreateLabel = (typeof createLabels)[number];
export type UpdateLabel = (typeof updateLabels)[number];
export type DeleteLabel = (typeof deleteLabels)[number];

export type SubmitLabel = CreateLabel | UpdateLabel | DeleteLabel;

export type ButtonSubmitCoreTProps = {
  label: SubmitLabel;
  dialogContent?: React.ReactNode;
  id?: string;
  initialSubmitData?: Record<string, any> | Record<string, any>[];
  isSubmitting?: boolean;
  isValid?: boolean;
  onSubmit?: () => Promise<any> | void;
  onSubmitDebounce?: number;
  submitData?: Record<string, any> | Record<string, any>[];
} & Omit<ButtonCoreProps, "children" | "permissionType">;

const ButtonSubmitCore = ({
  dialogContent,
  id,
  initialSubmitData,
  isSubmitting,
  isValid,
  label,
  onSubmit: onSubmitProp,
  onSubmitDebounce = _c.DEBOUNCE.SUBMIT_BUTTON,
  submitData,
  ...buttonProps
}: ButtonSubmitCoreTProps) => {
  const submitDisabled = isDisableSubmit({
    data: submitData,
    initialData: initialSubmitData,
    isValid,
  });

  if (DEBUG) {
    console.log("DEBUG BUTTON_SUBMIT_CORE: SubmitButtons");
    console.log(
      "DEBUG BUTTON_SUBMIT_CORE: diff",
      detailedDiff(submitData as object, initialSubmitData as object),
    );
    console.log("DEBUG BUTTON_SUBMIT_CORE: buttonProps", buttonProps);
    console.log("DEBUG BUTTON_SUBMIT_CORE: dialogContent", dialogContent);
    console.log(
      "DEBUG BUTTON_SUBMIT_CORE: initialSubmitData",
      initialSubmitData,
    );
    console.log("DEBUG BUTTON_SUBMIT_CORE: isSubmitting", isSubmitting);
    console.log("DEBUG BUTTON_SUBMIT_CORE: isValid", isValid);
    console.log("DEBUG BUTTON_SUBMIT_CORE: submitData", submitData);
    console.log("DEBUG BUTTON_SUBMIT_CORE: submitDisabled", submitDisabled);
    console.log(
      "DEBUG BUTTON_SUBMIT_CORE: isEqual",
      _.isEqual(submitData, initialSubmitData),
    );
  }

  const permissionType = getHasRightFromPermissionForButtonSubmit({
    label,
  });

  const [t] = useTranslation();
  const [submitPressed, setSubmitPressed] = React.useState(false);
  const [openYesNoDialog, setOpenYesNoDialog] = React.useState(false);

  const onSubmit = () => {
    setSubmitPressed(true);
    setOpenYesNoDialog(false);
    if (onSubmitProp) {
      onSubmitProp();
    }
  };

  return (
    <>
      <ButtonPrimary
        disabled={submitDisabled}
        id={`${id}-${label || "update"}`}
        isLoading={isSubmitting && submitPressed}
        onClick={() => {
          if (dialogContent) {
            setOpenYesNoDialog(true);
          } else {
            if (onSubmitDebounce) {
              // Debounce delay to the onClick so that form state can be updated before the form is submitted
              // This is needed because of TextInput is using debounce logic for input speed
              setTimeout(() => onSubmit(), onSubmitDebounce);
            } else {
              onSubmit();
            }
          }
        }}
        permissionType={permissionType}
        {...buttonProps}
      >
        {t(label)}
      </ButtonPrimary>

      {dialogContent && (
        <YesNoDialog
          open={openYesNoDialog}
          onYes={() => onSubmit()}
          onNo={() => setOpenYesNoDialog(false)}
        >
          {dialogContent}
        </YesNoDialog>
      )}
    </>
  );
};

export default ButtonSubmitCore;
