import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Box } from "@mui/material";
import { RootState } from "src/redux/reducers";
import { useGetMonthRevenueWithOperatingMarginQuery } from "src/redux/services/InsightService";
import { useGetReportTemplateYFiltersQuery } from "src/redux/services/ReportTemplateFilterService";
import { useSelector } from "react-redux";
import BuildItems from "src/components/Builders/Container/BuildItems";
import getStructure from "./DateRangeStructure";
import Section from "src/components/Basic/Simple/Sections/Section";
import toDay from "src/utils/date";
import useFormContainer from "src/hooks/useFormContainer";
import useTranslation from "src/hooks/useTranslationWrapper";

const RevenueVsOperatingCosts = ({ contractId }: { contractId?: string }) => {
  const [t] = useTranslation();

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  const {
    data: reportFiltersQuery,
    isLoading: isAllReportTemplateYFiltersLoading,
  } = useGetReportTemplateYFiltersQuery(undefined);
  const allReportTemplateYFilters = reportFiltersQuery?.data || [];

  const hasAccountNumberSeries =
    allReportTemplateYFilters
      ?.map((filter) => filter?.accountNumberSeries)
      ?.reduce((acc, val) => acc.concat(val), [])?.length > 0;

  const { activeCompanyId } = useSelector<RootState, any>(({ user }) => {
    return user.user;
  });

  const { formData, helperText, structure } = useFormContainer<{
    from: Date;
    to: Date;
  }>({
    getStructure,
    initialFormData: {
      // Default dates last 12 months
      from: new Date(currentYear, currentMonth - 12, 1),
      to: new Date(currentYear, currentMonth - 1, 1),
    },
  });
  const filter: any = {
    fromDate: toDay(formData?.from),
    toDate: toDay(formData?.to),
    companyId: activeCompanyId,
  };
  if (contractId) {
    filter.contractId = contractId;
  }
  const { data: queryResult, isLoading } =
    useGetMonthRevenueWithOperatingMarginQuery(filter);

  const commonPropsBuildItem = {
    data: formData,
    helperText,
    disabled: !hasAccountNumberSeries,
  };

  return (
    <Section
      label="Revenue v. Operating Margin %"
      rightBoxArea={BuildItems({
        items: structure.items,
        ...commonPropsBuildItem,
      })}
      rightBoxAreaSx={{
        display: "flex",
        columnGap: "var(--space-8px)",
        marginBottom: "var(--space-12px)",
      }}
      isLoading={isLoading || isAllReportTemplateYFiltersLoading}
    >
      {!hasAccountNumberSeries ? (
        <Box
          sx={{
            fontSize: 12,
            fontWeight: "bold",
            color: "var(--negative-1)",
            margin: "auto",
          }}
        >
          {t(
            "This company is missing income or cost number series, therefore categorised incomes and costs will not be displayed.",
          )}
        </Box>
      ) : (
        <></>
      )}
      <ResponsiveContainer width="100%" height={500}>
        <ComposedChart
          width={1000}
          height={500}
          data={queryResult || []}
          margin={{
            top: 10,
            right: 20,
            left: 20,
            bottom: 10,
          }}
        >
          {hasAccountNumberSeries ? (
            <>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis
                yAxisId="left"
                orientation="left"
                stroke="var(--information-5)"
              >
                <Label
                  style={{
                    textAnchor: "middle",
                    fontSize: "120%",
                    fill: "var(--information-5)",
                  }}
                  angle={270}
                  position="left"
                  value="Revenues"
                />
              </YAxis>
              <YAxis
                yAxisId="right"
                orientation="right"
                stroke="var(--negative-2)"
              >
                <Label
                  style={{
                    textAnchor: "middle",
                    fontSize: "120%",
                    fill: "var(--negative-2)",
                  }}
                  angle={90}
                  position="right"
                  value="Operating Profit Margin %"
                />
              </YAxis>
              <Tooltip />
              <Legend />
              <Bar
                name={t("Revenues")}
                dataKey="revenues"
                fill="var(--information-5)"
                yAxisId="left"
                maxBarSize={80}
              />
              <Line
                name={t("Operating Profit Margin %")}
                dataKey="operatingMargin"
                yAxisId="right"
                type="monotone"
                stroke="var(--negative-2)"
              />
            </>
          ) : (
            <>
              <CartesianGrid
                strokeDasharray="3 3"
                // set background color for Chart
                fill="var(--gray-10)"
              />
              <XAxis dataKey="month" />
              <Legend />
              <Bar
                name={t("Revenues")}
                dataKey=""
                fill="var(--information-5)"
              />
              <Line
                name={t("Operating Profit Margin %")}
                dataKey=""
                stroke="var(--negative-2)"
              />
            </>
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </Section>
  );
};

export default RevenueVsOperatingCosts;
