/**
 *Created by Mikael Lindahl on 2023-10-17
 */

import { UserFormTableGetStructureOptional } from "src/hooks/useFormTable";
import { TemplateTagContractOfferItem } from "src/accurasee-backend-types/app/general/microsoft_drive/microsoft_drive.types";
import {
  MICROSOFT_DOC_TEMPLATE_END_DELIMITER,
  MICROSOFT_DOC_TEMPLATE_START_DELIMITER,
} from "src/context/MicrosoftDriveProvider/Lib/MicrosoftDocTemplateProcessor/Utils/WordHandler";

export type GetStructureExtraProps = {};
export const getStructure: UserFormTableGetStructureOptional<
  TemplateTagContractOfferItem,
  GetStructureExtraProps
> = () => {
  return {
    items: [
      {
        type: "text",
        dataName: "key",
        headerLabel: "Template key",
        getValue: ({ data, rowIndex }) =>
          `${MICROSOFT_DOC_TEMPLATE_START_DELIMITER}${data[rowIndex].key}${MICROSOFT_DOC_TEMPLATE_END_DELIMITER}`,
      },
      {
        type: "text",
        dataName: "description",
        headerLabel: "Value description",
      },
    ],
  };
};
