import Box from "@mui/material/Box";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ContractInsightWithPermissions } from "src/accurasee-backend-types/app/insight/insight.types";
import { ContractStatus } from "src/accurasee-backend-types/app/contracts/contract.types";
import { getStructure } from "./ContractTableStructure";
import { MUIDataTableColumnDef } from "mui-datatables";
import { useGetCompanyQuery } from "src/redux/services/CompanyService";
import { useGetCompanyUsersQuery } from "src/redux/services/UserService";
import { useGetContractTypesQuery } from "src/redux/services/ContractTypesServices";
import { useGetInsightContractsQuery } from "src/redux/services/InsightService";
import { useRef, useState } from "react";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import ContractInsightsDateFilter from "./ContractDateFilter";
import getSummaries from "src/utils/getSummaries";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import Page from "src/components/Basic/Mixed/Pages/Page";
import toDay from "src/utils/date";
import useMUIDataTableOptionsServerSide from "src/hooks/useMUIDataTableOptionsServerSide";
import getExportData from "src/components/Builders/Table/Utils/getExportData";
import ExportButton from "src/components/Basic/Mixed/Buttons/ExportButton";

export type Filter = {
  status: ContractStatus[];
  sortBy: string;
  sort: "asc" | "desc";
  // Default is this fiscal year, for ex. from 1.1.2023 - today's date
  startDate: Date;
  endDate: Date;
};

interface ContractInsightsTableProps {
  filter: Filter;
}

const ContractInsightsTable = ({ filter }: ContractInsightsTableProps) => {
  const { data: companyQuery, isLoading: isLoadingCompany } =
    useGetCompanyQuery(undefined);
  const company = companyQuery?.company;

  const { data: contractTypesQuery, isLoading: isLoadingContractType } =
    useGetContractTypesQuery(undefined);
  const contractTypes = contractTypesQuery?.data || [];

  const { data: usersResponse, isLoading: isLoadingUsers } =
    useGetCompanyUsersQuery(undefined);
  const users = usersResponse?.data || [];

  const [filterData, setFilterData] = useState({
    startDate: filter.startDate,
    endDate: filter.endDate,
  });

  const exportDataRef = useRef<ReturnType<typeof getExportData>>([]);

  const {
    options,
    data: contracts,
    isLoading,
    isFetching,
  } = useMUIDataTableOptionsServerSide<ContractInsightWithPermissions>({
    dataName: "contract insights",
    defaultPaginationOptions: [10, 15, 50, 100, 200],
    filterInit: {
      base: {
        pageSize: 15,
        sort: filter?.sort,
        sortBy: filter?.sortBy,
      },
      match: {
        endDate: toDay(filterData?.endDate),
        startDate: toDay(filterData?.startDate),
        status: filter.status,
      },
    },
    filterOptions: {
      selectableRows: "none",
    },
    triggerChangeOnFilterInit: ["endDate", "startDate"],
    useGetDataQuery: useGetInsightContractsQuery,
    customButton: ({ additionalFilter, setAdditionalFilter }) => {
      return (
        <>
          <ExportButton
            buttonType="icon"
            color="primary"
            data={() => exportDataRef.current}
            key={"export-xlsx"}
            fileName={"contract-insights.xlsx"}
            fileType={"xlsx"}
            variant="contained"
          >
            <FileDownloadIcon />
          </ExportButton>
          <Box sx={{ display: "flex", columnGap: "var(--space-8px)" }}>
            <ContractInsightsDateFilter
              additionalFilter={additionalFilter}
              filterData={filterData}
              setAdditionalFilter={setAdditionalFilter}
              setFilterData={setFilterData}
            />
          </Box>
        </>
      );
    },
  });

  const contractsWithAdditionalData = contracts?.map((contract, i) => {
    return { ...contract, number: i + 1 };
  });

  const { dataWithSummaries: contractsWithSummaries } = getSummaries({
    data: contractsWithAdditionalData,
    sumTextKey: "number",
    keys: [
      { name: "yearToDate" },
      { name: "result" },
      { name: "incomes" },
      { name: "costs" },
      { name: "plannedInvoices" },
    ],
  });

  const structure = getStructure({
    extraProps: {
      contractTypes,
      currencyCode: company?.currencyCode,
      users,
    },
  });

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle({
      data: contractsWithSummaries,
      disabled: true,
      item,
    }),
  );

  return (
    <Page
      label="Contract insights"
      sx={{ borderRadius: "8px" }}
      breadcrumbs={[{ label: "Insights" }, { label: "Contract" }]}
      isLoading={
        isLoading || isLoadingCompany || isLoadingContractType || isLoadingUsers
      }
    >
      <MuiDataTableWrapper
        data={contractsWithSummaries}
        columns={columns}
        isFetching={isFetching}
        options={{
          ...options,
          onTableChange: (_, tableState) => {
            exportDataRef.current = getExportData(tableState);
          },
        }}
        tableType="main-with-filter"
        noBackground
      />
    </Page>
  );
};

export default ContractInsightsTable;
