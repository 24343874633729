import { Box } from "@mui/material";
import { useEffect, useState } from "react";

interface FloatingWrapperProps {
  children: React.ReactNode;
  isBlur?: boolean;
  isFullWidth?: boolean; // fixed at the bottom as a Footer
  position?:
    | "bottom-left"
    | "bottom-right"
    | "bottom-center"
    | "top-left"
    | "top-right"
    | "top-center";
}

const FloatingWrapper = ({
  children,
  position = "bottom-center",
  isBlur,
  isFullWidth,
}: FloatingWrapperProps) => {
  const [shadow, setShadow] = useState(
    window.innerHeight + window.scrollY >=
      document.documentElement.scrollHeight,
  );

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.innerHeight + window.scrollY;
      const documentHeight = document.documentElement.scrollHeight;

      // Check if the user is at the bottom of the page
      if (scrollPosition >= documentHeight) {
        setShadow(false); // Remove shadow when at the bottom
      } else {
        setShadow(true); // Show shadow when not at the bottom
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getPositionStyles = () => {
    switch (position) {
      case "bottom-left":
        return { bottom: "48px", left: "264px" }; // next to Sidebar
      case "bottom-right":
        return { bottom: "48px", right: "56px" };
      case "bottom-center":
        return {
          bottom: "48px",
          left: "calc(50% + 120px)",
          transform: "translateX(-50%)",
        };
      case "top-left":
        return { top: "112px", left: "264px" };
      case "top-right":
        return { top: "112px", right: "56px" };
      case "top-center":
        return {
          top: "112px",
          left: "calc(50% + 120px)",
          transform: "translateX(-50%)",
        };
      default:
        return { bottom: "48px", left: "264px" };
    }
  };
  return (
    <>
      <Box sx={{ height: "72px" }} />
      <Box
        sx={{
          position: "fixed",
          zIndex: 1000,
          padding: "var(--space-8px) var(--space-16px)",
          borderRadius: "8px",
          boxShadow:
            "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",

          ...getPositionStyles(),
          ...(isBlur
            ? {
                backdropFilter: "blur(10px)", // Apply the blur effect
                backgroundColor: "rgba(255, 255, 255, 0.6)", // Semi-transparent background
              }
            : { backgroundColor: "var(--white-primary)" }),

          ...(isFullWidth
            ? {
                padding: "var(--space-24px)",
                paddingBottom: "var(--space-32px)",
                width: "calc(100% - 240px)",
                backgroundColor: "#F6F7FF",
                borderRadius: 0,
                bottom: 0,
                boxShadow: shadow ? "0 -2px 10px rgba(0, 0, 0, 0.1)" : "none",
              }
            : {}),
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default FloatingWrapper;
