import Tooltip from "@mui/material/Tooltip";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

type UseMUICustomToolBarOptions = {
  actionFunction: () => void;
  showButton: boolean;
  title: string;
};

export const CustomToolbarButtonWithTooltip = (
  props: UseMUICustomToolBarOptions,
) => {
  const [t] = useTranslation();

  return props.showButton ? (
    <>
      <Tooltip title={props.title}>
        <Button
          color="primary"
          variant="outlined"
          disableRipple
          onClick={props.actionFunction}
        >
          {t(props.title)}
        </Button>
      </Tooltip>
    </>
  ) : (
    <></>
  );
};
