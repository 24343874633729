import { MUIDataTableColumnDef } from "mui-datatables";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import { useMUIDatTableOptions } from "src/hooks/useMUIDataTableOptions";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import { ProjectTrackerRegion } from "../../../../accurasee-backend-types/app/project_tracker/project_tracker.types";
import ProjectTrackerRegionSubTable from "./ProjectTrackerRegionSubTable";
import { getStructure } from "./ProjectTrackerRegionGetStructure";

interface ProjectTrackerRegionTableProps {
  data: ProjectTrackerRegion[];
}

export function ProjectTrackerRegionTable({
  data,
}: ProjectTrackerRegionTableProps) {
  const mainData = data.filter((d) => d.depth === 0);
  const structure = getStructure({ extraProps: { nameLabel: "Region" } });

  const ProjectTrackerRegionTableColumns: MUIDataTableColumnDef[] =
    structure.items.map((item) =>
      BuilderColumnOptionsSingle<ProjectTrackerRegion>({
        data: mainData,
        disabled: true,
        item,
      }),
    );

  const { options } = useMUIDatTableOptions({
    pagination: data.length >= 50,
    subtableFunction: (
      rowData: { props: { data: ProjectTrackerRegion[] } }[],
      rowMeta: any,
    ) => {
      const current = rowData[rowMeta.dataIndex].props.data[rowMeta.dataIndex];

      let subData = data.filter(
        (d) => d.depth >= 1 && d.dependencyId === current._id,
      );

      const subDataIds = subData.map((s) => s._id);
      subData = [
        ...subData,
        ...data.filter(
          (d) =>
            d.dependencyId &&
            d.depth >= 1 &&
            subDataIds.includes(d.dependencyId),
        ),
      ];

      return <ProjectTrackerRegionSubTable data={subData} />;
    },
  });

  return (
    <MuiDataTableWrapper
      nameSpace={"main-table"}
      tableType="main"
      noBackground={true}
      title={""}
      data={mainData}
      columns={ProjectTrackerRegionTableColumns}
      options={options}
      fontSizeCells={"14px"}
    />
  );
}

export default ProjectTrackerRegionTable;
