/**
 *Created by Mikael Lindahl on 2023-12-28
 */
import SectionCore, {
  SectionCoreTProps,
} from "../../Mixed/Sections/SectionCore";

const SectionTransparentForTableWithToolbar = (
  props: Omit<SectionCoreTProps, "withTightTableHeading">,
) => {
  return (
    <SectionCore withTightTableHeading={true} {...props}>
      {props.children}
    </SectionCore>
  );
};

export default SectionTransparentForTableWithToolbar;
