import { escape } from "src/utils/translate";
import { useGetContractQuery } from "src/redux/services/ContractService";
import { useGetReportTemplatesQuery } from "src/redux/services/ReportTemplateService";
import { useParams } from "react-router-dom";
import Page from "src/components/Basic/Mixed/Pages/Page";
import InsightReportsList from "src/screens/App/Insights/Reports/components/ReportsList";

const ContractReports = () => {
  const { contractId } = useParams<{ contractId: string }>();
  const { data: contract, isLoading } = useGetContractQuery(contractId);

  const { data: dataQuery, isLoading: isReportTemplatesLoading } =
    useGetReportTemplatesQuery(undefined);
  const reportTemplates = dataQuery?.data || [];

  return (
    <Page
      label="Reports"
      breadcrumbs={[
        { label: "Contracts", link: "/app/contracts" },
        {
          label: `Contract${escape(` ${contract?.projectExternalId || ""}`)}`,
          link: `/app/contracts/${contractId}`,
        },
        { label: "Reports" },
      ]}
      isLoading={isLoading || isReportTemplatesLoading}
    >
      <InsightReportsList reportTemplates={reportTemplates} />
    </Page>
  );
};

export default ContractReports;
