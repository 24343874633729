import { MUIDataTableColumnDef } from "mui-datatables";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import { useMUIDatTableOptions } from "src/hooks/useMUIDataTableOptions";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import {
  ProjectTrackerPercentageOfCompletion,
  ProjectTrackerPercentageOfCompletionSub,
} from "../../../../accurasee-backend-types/app/project_tracker/project_tracker.types";
import { getStructure } from "./ProjectTrackerPercentageOfCompletionGetStructure";
import ProjectTrackerPercentageOfCompletionSubTable from "./ProjectTrackerPercentageOfCompletionSubTable";

interface ProjectTrackerPercentageOfCompletionTableProps {
  data: ProjectTrackerPercentageOfCompletion[];
  dataSub: ProjectTrackerPercentageOfCompletionSub[];
}

export function ProjectTrackerPercentageOfCompletionTable({
  data,
  dataSub,
}: ProjectTrackerPercentageOfCompletionTableProps) {
  const structure = getStructure();

  const ProjectTrackerPercentageOfCompletionTableColumns: MUIDataTableColumnDef[] =
    structure.items
      .filter((c) => ["main", "main-and-sub"].includes(String(c.itemType)))
      .map((item) =>
        BuilderColumnOptionsSingle<ProjectTrackerPercentageOfCompletion>({
          data,
          disabled: true,
          item,
        }),
      );

  const { options } = useMUIDatTableOptions({
    pagination: data.length >= 50,
    subtableFunction: (
      rowData: { props: { data: ProjectTrackerPercentageOfCompletionSub[] } }[],
      rowMeta: any,
    ) => {
      const current = rowData[rowMeta.dataIndex].props.data[rowMeta.dataIndex];

      let subData = dataSub.filter((d) => d.userId === current.userId);

      return <ProjectTrackerPercentageOfCompletionSubTable data={subData} />;
    },
  });

  return (
    <MuiDataTableWrapper
      nameSpace={"main-table"}
      title={""}
      data={data}
      columns={ProjectTrackerPercentageOfCompletionTableColumns}
      options={options}
    />
  );
}

export default ProjectTrackerPercentageOfCompletionTable;
