/**
 * This is to get icon and style by type (Notifications from BE)
 */

import {
  Check as CheckIcon,
  CheckCircle as CheckCircleIcon,
  NotificationsNone as NotificationsIcon,
  ThumbUp as ThumbUpIcon,
  ShoppingCart as ShoppingCartIcon,
  LocalOffer as TicketIcon,
  BusinessCenter as DeliveredIcon,
  SmsFailed as FeedbackIcon,
  DiscFull as DiscIcon,
  Email as MessageIcon,
  Report as ReportIcon,
  Error as DefenceIcon,
  AccountBox as CustomerIcon,
  Done as ShippedIcon,
  Publish as UploadIcon,
  Description as DescriptionIcon,
  Delete as DeleteIcon,
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon,
  FileDownload,
  Undo as UndoIcon,
  Adb as DebugIcon,
  ContentCopy as ContentCopyIcon,
  ErrorOutlineOutlined as ErrorOutlineOutlinedIcon,
  InfoOutlined as InfoOutlinedIcon,
  Timelapse as TimelapseIcon,
  Visibility as VisibilityIcon,
  WarningAmber as WarningAmberIcon,
} from "@mui/icons-material";
import { IconTypes } from "../CommonBuilderTableTypes";
import getStylingByStatusType, {
  StylingType,
  stylingProps,
} from "src/utils/getStylingByStatusType";

const getIconByType = (type: IconTypes | string = "message") => {
  let icon: any;
  let style: StylingType = "";
  switch (type) {
    case "alert":
      icon = <ErrorOutlineOutlinedIcon />;
      break;
    case "check":
      icon = <CheckIcon />;
      style = "black";
      break;
    case "check_circle":
      icon = <CheckCircleIcon />;
      style = "success-no-background";
      break;
    case "contract":
      icon = <DescriptionIcon />;
      style = "information";
      break;
    case "copy":
      icon = <ContentCopyIcon />;
      style = "information";
      break;
    case "customer":
      icon = <CustomerIcon />;
      style = "information";
      break;
    case "debug":
      icon = <DebugIcon />;
      break;
    case "defence":
      icon = <DefenceIcon />;
      style = "warning-1";
      break;
    case "delete":
      icon = <DeleteForeverIcon />;
      break;
    case "delete_no_cross":
      icon = <DeleteIcon />;
      break;
    case "delivered":
      icon = <DeliveredIcon />;
      style = "information-1";
      break;
    case "disc":
      icon = <DiscIcon />;
      style = "information";
      break;
    case "download":
      icon = <FileDownload />;
      break;
    case "e_commerce":
      icon = <ShoppingCartIcon />;
      style = "information";
      break;
    case "edit":
      icon = <EditIcon />;
      break;
    case "feedback":
      icon = <FeedbackIcon />;
      style = "disabled";
      break;
    case "incomplete":
      icon = <TimelapseIcon />;
      style = "alert";
      break;
    case "information":
      icon = <InfoOutlinedIcon />;
      style = "alert";
      break;
    case "message":
      icon = <MessageIcon />;
      style = "disabled";
      break;
    case "notification":
      icon = <NotificationsIcon />;
      style = "disabled";
      break;
    case "offer":
      icon = <TicketIcon />;
      style = "warning";
      break;
    case "report":
      icon = <ReportIcon />;
      style = "alert";
      break;
    case "shipped":
      icon = <ShippedIcon />;
      style = "success-1";
      break;
    case "undo":
      icon = <UndoIcon />;
      break;
    case "upload":
      icon = <UploadIcon />;
      style = "information";
      break;
    case "view":
      icon = <VisibilityIcon />;
      break;
    case "warning":
      icon = <WarningAmberIcon />;
      break;
    default:
      // info
      icon = <ThumbUpIcon />;
      style = "information";
      break;
  }

  return {
    icon,
    style,
    styling: getStylingByStatusType(style) as stylingProps,
  };
};
export default getIconByType;
