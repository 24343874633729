import { MUIDataTableColumnDef } from "mui-datatables";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import { useMUIDatTableOptions } from "src/hooks/useMUIDataTableOptions";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import {
  ProjectTrackerPercentageOfCompletionVariant2,
  ProjectTrackerPercentageOfCompletionVariant2Sub,
} from "../../../../accurasee-backend-types/app/project_tracker/project_tracker.types";
import { getStructure } from "./ProjectTrackerPercentageOfCompletionVariant2GetStructure";
import ProjectTrackerPercentageOfCompletionVariant2SubTable from "./ProjectTrackerPercentageOfCompletionVariant2SubTable";

interface ProjectTrackerPercentageOfCompletionVariant2TableProps {
  data: ProjectTrackerPercentageOfCompletionVariant2[];
  dataSub: ProjectTrackerPercentageOfCompletionVariant2Sub[];
}

export function ProjectTrackerPercentageOfCompletionTable({
  data,
  dataSub,
}: ProjectTrackerPercentageOfCompletionVariant2TableProps) {
  const structure = getStructure();

  const ProjectTrackerPercentageOfCompletionVariant2TableColumns: MUIDataTableColumnDef[] =
    structure.items
      .filter((c) => ["main", "main-and-sub"].includes(String(c.itemType)))
      .map((item) =>
        BuilderColumnOptionsSingle<ProjectTrackerPercentageOfCompletionVariant2>(
          {
            data,
            disabled: true,
            item,
          },
        ),
      );

  const { options } = useMUIDatTableOptions({
    pagination: data.length >= 50,
    subtableFunction: (
      rowData: {
        props: { data: ProjectTrackerPercentageOfCompletionVariant2Sub[] };
      }[],
      rowMeta: any,
    ) => {
      const current = rowData[rowMeta.dataIndex].props.data[rowMeta.dataIndex];

      let subData = dataSub.filter((d) => d.userId === current.userId);

      return (
        <ProjectTrackerPercentageOfCompletionVariant2SubTable data={subData} />
      );
    },
  });

  return (
    <MuiDataTableWrapper
      nameSpace={"main-table"}
      title={""}
      data={data}
      columns={ProjectTrackerPercentageOfCompletionVariant2TableColumns}
      options={options}
    />
  );
}

export default ProjectTrackerPercentageOfCompletionTable;
