import { Typography } from "@mui/material";
import {
  useCreateReportTemplateMutation,
  useGetReportTemplatesQuery,
  useUpdateReportTemplateOrderMutation,
} from "src/redux/services/ReportTemplateService";
import Page from "src/components/Basic/Mixed/Pages/Page";
import Report from "./Report";
import ButtonAddBoxIcon from "src/components/Basic/Simple/Buttons/ButtonAddBoxIcon";

enum MoveDirection {
  Up = -1,
  Down = 1,
}

const Reports = () => {
  const {
    data: dataQuery,
    isLoading: isLoadingReports,
    isFetching: isFetchingReports,
  } = useGetReportTemplatesQuery(undefined);
  const [createReport, { isLoading: isCreatingReport }] =
    useCreateReportTemplateMutation(undefined);
  const [updateReportTemplateOrder] = useUpdateReportTemplateOrderMutation();

  const reportTemplates = dataQuery?.data || [];

  const isLoading = isLoadingReports || isCreatingReport || isFetchingReports;

  const handleCreateReport = () => {
    createReport({ name: "New report" });
  };

  const handleMove = (index: number, direction: MoveDirection) => {
    if (index === 0 && direction === MoveDirection.Up) {
      return;
    }
    if (
      index === reportTemplates.length - 1 &&
      direction === MoveDirection.Down
    ) {
      return;
    }

    const newReportTemplateIds = [...reportTemplates].map((template) =>
      String(template._id),
    );

    [newReportTemplateIds[index], newReportTemplateIds[index + direction]] = [
      newReportTemplateIds[index + direction],
      newReportTemplateIds[index],
    ];

    updateReportTemplateOrder(
      newReportTemplateIds.map((id, index: number) => ({
        _id: id,
        order: index,
      })),
    );
  };

  return (
    <Page
      label={"Reports"}
      breadcrumbs={[{ label: "Setup" }, { label: "Reports" }]}
      isFullWidth={false}
      isLoading={isLoadingReports || isFetchingReports}
      boxRight={
        <ButtonAddBoxIcon
          label="Create report"
          onSubmit={handleCreateReport}
          isLoading={isCreatingReport}
          disabled={isLoading}
        />
      }
    >
      {reportTemplates?.length === 0 ? (
        <Typography>{"No report template exists ..."}</Typography>
      ) : (
        reportTemplates?.map((template, index: number) => (
          <Report
            data={template}
            onMoveUp={() => handleMove(index, MoveDirection.Up)}
            onMoveDown={() => handleMove(index, MoveDirection.Down)}
            disableMoveDown={index === reportTemplates.length - 1}
            disableMoveUp={index === 0}
          />
        ))
      )}
    </Page>
  );
};

export default Reports;
