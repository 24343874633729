/**
 *Created by Mikael Lindahl on 2022-12-07
 */

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const useStyles = makeStyles((theme: any) =>
  createStyles({
    modalStyle: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "fit-content",
      maxWidth: "1400px",
      minWidth: "640px",
      height: "fit-content",
      maxHeight: "90%",
      backgroundColor: "var(--white-primary)",
      borderRadius: "8px",
      boxShadow: "0px 5px 13px var(----black-2)",
      padding: "var(--space-32px) var(--space-48px)",
      overflow: "hidden",
      "& input": {
        padding: "8px",
      },
      "& .MuiSelect-select": {
        padding: "8px",
      },
    },
    articleWrapper: {
      borderBottom: "1px solid lightgrey",
      paddingTop: "1rem",
      [theme.breakpoints.down("xl")]: {
        minWidth: "1800px",
      },
    },
    collapse: {
      width: "100%",
      [theme.breakpoints.down("xl")]: {
        minWidth: "1000px",
        overflowX: "scroll",
      },
    },
    contractArticleSection: {
      alignItems: "baseline",
      backgroundColor: theme.palette.white.main,

      // padding: "2rem",
      // marginTop: "1rem",
    },
    defaultChecked: {
      "& span": {
        padding: 0,
      },
    },
    labelFieldStyle: {
      transform: "none",
      position: "relative",
      fontSize: "12px",
      marginBottom: "6px",
    },
    requiredField: {
      "& p": {
        color: "red !important",
        margin: "3px 0",
      },
    },
  }),
);

export default useStyles;
