import {
  CommonCallbackProps,
  StructureItemTable,
} from "src/components/Builders/Table/CommonBuilderTableTypes";
import {
  getCustomMinMaxFilterListOptions,
  getCustomMinMaxfilterOptions,
  getCustomMultiselectFilterOptions,
} from "src/components/Complex/Containers/Filterhelper";
import { ContractOffer } from "src/accurasee-backend-types/app/contract_offer/contract_offer.types";
import { Customer } from "src/accurasee-backend-types/app/customer/customer.types";
import { ReturnUser } from "src/accurasee-backend-types/app/user/user.types";
import { Types } from "mongoose";
import { UserFormTableGetStructureOptional } from "src/hooks/useFormTable";
import getContractOfferExternalId from "src/utils/getContractOfferExternalId";
import getCustomerName from "src/utils/getCustomerName";
import getDisplayValueById from "src/utils/getDisplayValueById";
import getSelectOptions from "src/utils/getSelectOptions";
import getTableFilterOwnerIds from "src/utils/getTableFilterOwnerIds";

export interface GetStructureExtraProps {
  currencyCode?: string;
  customers?: Customer[];
  idSummaries?: Types.ObjectId;
  grouping?: "active" | "archived";
  users?: ReturnUser[];
}

const getStructure: UserFormTableGetStructureOptional<
  Partial<ContractOffer>,
  GetStructureExtraProps
> = (props) => {
  const t = props?.t ? props.t : (s: string) => s;

  return {
    items: [
      {
        type: "text",
        dataName: "number",
        headerLabel: "#",
        sort: true,
      },
      {
        dataName: "projectExternalId",
        headerLabel: "Offer Id",
        type: "link",
        getValue: ({ data, rowIndex }: CommonCallbackProps) =>
          getContractOfferExternalId(data[rowIndex]),
        redirectLink: (_props: CommonCallbackProps) =>
          `/app/contract-offers/overview/${String(_props.data[_props.rowIndex]._id)}`,
        sort: true,
      },
      {
        type: "text",
        dataName: "name",
        headerLabel: "Offer name",
        sort: true,
      },
      {
        type: "text",
        dataName: "description",
        headerLabel: "Description",
        sort: true,
      },
      {
        type: "text",
        dataName: "ownerIds",
        headerLabel: "Responsible",
        getValue: ({ data, rowIndex }: CommonCallbackProps) => {
          const owners =
            data[rowIndex]?.ownerIds?.map((ownerId: Types.ObjectId) => {
              const owner = props?.extraProps?.users?.find(
                (e) => e._id === ownerId,
              );
              return `${owner?.firstName} ${owner?.lastName}`;
            }) || [];

          return owners?.join(", ") || " ";
        },
        ...getTableFilterOwnerIds({
          users: props?.extraProps?.users,
          dataLabel: t("Responsible"),
        }),
        sort: true,
      },
      {
        type: "text_currency",
        currencyCode: props?.extraProps?.currencyCode,
        dataName: "offerPrice",
        headerLabel: "Offer price",
        alignColumnContent: "end",
        filter: true,
        filterType: "custom",
        customFilterListOptions: getCustomMinMaxFilterListOptions(
          t("Offer price"),
        ),
        filterOptions: getCustomMinMaxfilterOptions(t("Offer price"), "number"),
        sort: true,
      },
      {
        type: "badge",
        dataName: "customerStatus",
        headerLabel: "Status",
        alignColumnContent: "center",
        sort: true,
        filter: true,
        filterOptions: {
          names: [
            "Approved",
            "Cancelled",
            "Declined",
            "Draft",
            "Resent",
            "Sent",
          ],
          renderValue: (value: string) => t(value),
        },
        customFilterListOptions: {
          render: (value: string) => t(value),
        },
      },
      {
        type: "text",
        dataName: "customerId",
        headerLabel: "Customer",
        getValue: ({ data, rowIndex }: CommonCallbackProps) => {
          const customerId = data[rowIndex]?.customerId;
          return getCustomerName(
            props?.extraProps?.customers?.find((c) => c._id === customerId),
            { excludeExternalId: true },
          );
        },
        filter: true,
        filterType: "custom",
        filterOptions: getCustomMultiselectFilterOptions({
          dataLabel: t("Customer"),
          options: getSelectOptions({
            data: props?.extraProps?.customers,
            label: (c) => c.name,
            value: (c) => String(c._id),
          }),
          renderValue: (v: string) =>
            getDisplayValueById({
              idToMatch: v,
              items: props?.extraProps?.customers || [],
            }),
        }),
        customFilterListOptions: {
          render: (values: string[]) =>
            values.map((v: string) =>
              getDisplayValueById({
                idToMatch: v,
                items: props?.extraProps?.customers || [],
              }),
            ),
        },
        sort: true,
      },
      {
        type: "date",
        dataName: "createdAt",
        headerLabel: "Created",
        sort: true,
      },
      {
        type: "date",
        dataName: "deadline",
        headerLabel: "Deadline",
        filter: true,
        filterType: "custom",
        customFilterListOptions: getCustomMinMaxFilterListOptions(
          t("Deadline"),
        ),
        filterOptions: getCustomMinMaxfilterOptions(t("Deadline"), "date"),
        sort: true,
      },
      {
        showCellWhen: ({ data, rowIndex }: CommonCallbackProps) => {
          return data[rowIndex]?.grouping === "archived";
        },
        type: "icon",
        iconType: "check",
        dataName: "grouping",
        headerLabel: "Archived",
        alignColumnContent: "center",
        sort: true,
      },
    ].map(
      (item, i) =>
        ({
          ...item,
          ...(i !== 0 && item.type !== "text_currency"
            ? {
                showCellWhen: (_props) =>
                  _props.data[_props.rowIndex]?._id !==
                    props?.extraProps?.idSummaries &&
                  (item.showCellWhen ? item.showCellWhen(_props) : true),
              }
            : {}),
        }) as StructureItemTable,
    ),
  };
};

export const getSubcontractOfferStructure: UserFormTableGetStructureOptional<
  Partial<ContractOffer>
> = (props) => {
  // Same structure from Contract offer
  const structure = getStructure(props);

  // Filter out keys containing "filter"
  const filteredItems = structure.items.map((item) => {
    return Object.entries(item).reduce(
      (acc, [key, value]) => {
        if (!key.includes("filter")) {
          (acc as any)[key] = value;
        }
        return acc;
      },
      {} as StructureItemTable<Partial<ContractOffer>>,
    );
  });

  return {
    items: filteredItems,
  };
};

export const dataNames = getStructure().items.map((item) => item.dataName);
export default getStructure;
