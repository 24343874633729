/**
 *Created by Mikael Lindahl on 2023-04-27
 */
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import clone from "src/utils/clone";
import parseRestApiErrorMessage from "../utils/parseRestApiErrorMessage";
import useOnSubmitFiles from "./useOnSubmitFiles";
import useTranslation from "./useTranslationWrapper";
import { FileStorageExtra } from "../components/Builders/Container/ItemTypes/FileItem";
import { useCreateArticleMutation } from "../redux/services/ArticleService";
import { useDeleteApiKeyMutation } from "../redux/services/CompanyService";
import { usePatchArticleMutation } from "../redux/services/ArticleService";
import { usePatchBudgetMutation } from "../redux/services/BudgetService";
import { usePatchInvoiceplanTemplateMutation } from "../redux/services/InvoicePlanTemplateService";
import { usePatchPlannedInvoiceMutation } from "../redux/services/PlannedinvoiceService";
import { useSnackbar } from "notistack";
import {
  useCreateContractMutation,
  useDeleteContractMutation,
  usePatchContractMutation,
  usePatchContractsMutation,
} from "../redux/services/ContractService";
import {
  useCreateInvoiceplanMutation,
  useDeleteInvoiceplanMutation,
  usePatchInvoiceplanMutation,
} from "../redux/services/InvoiceplanService";
import {
  useCreateTranslationsMutation,
  useDeleteTranslationsMutation,
  useUpdateTranslationsMutation,
} from "../redux/services/TranslationService";
import { useSendInviteMutation } from "src/redux/services/InviteService";
import {
  useCreateContractOfferCloneMutation,
  useCreateContractOfferMutation,
  useCreateSubContractMutation,
  useCreateSubContractOfferMutation,
} from "src/redux/services/ContractOfferService";
import { useCreateActivityLogMutation } from "src/redux/services/ActivityLogService";

export type SubmitAction = "create" | "update" | "delete";
type SubmitStatus = "success" | "failure";
type OnSubmitData<
  DataTypeCreate = any,
  DataTypeUpdate = any,
  DataTypeDelete = any,
> = {
  create?: DataTypeCreate;
  delete?: DataTypeDelete;
  update?: DataTypeUpdate & { files?: File[] };
};

// It is enough with using typeof for all create mutations
// since they all have the same return type
export type DataTypeCreateMutation = ReturnType<
  | typeof useCreateArticleMutation
  | typeof useSendInviteMutation
  | typeof useCreateTranslationsMutation
  | typeof useCreateInvoiceplanMutation
  | typeof useCreateContractOfferCloneMutation
  | typeof useCreateActivityLogMutation
  | typeof useCreateSubContractMutation
  | typeof useCreateSubContractOfferMutation
  | typeof useCreateContractMutation
  | typeof useCreateContractOfferMutation
>[0];

export type DataTypeDeleteMutation = ReturnType<
  | typeof useDeleteApiKeyMutation
  | typeof useDeleteContractMutation
  | typeof useDeleteInvoiceplanMutation
  | typeof useDeleteTranslationsMutation
>[0];

export type DataTypeUpdateMutation = ReturnType<
  | typeof usePatchArticleMutation
  | typeof usePatchBudgetMutation
  | typeof usePatchContractMutation
  | typeof usePatchContractsMutation
  | typeof usePatchInvoiceplanMutation
  | typeof usePatchInvoiceplanTemplateMutation
  | typeof usePatchPlannedInvoiceMutation
  | typeof useUpdateTranslationsMutation
>[0];

export type UseOnSubmit<
  DataTypeCreate = any,
  DataTypeUpdate = any,
  DataTypeDelete = any,
> = {
  apiMutations: {
    create?: DataTypeCreateMutation;
    delete?: DataTypeDeleteMutation;
    update?: DataTypeUpdateMutation;
  };
  data?: OnSubmitData<DataTypeCreate, DataTypeUpdate, DataTypeDelete>;
  dataId?: string;
  name: string;
  rerouteUrlOnSuccess?: {
    create?: ({ responseData }: { responseData?: DataTypeCreate }) => string;
    update?: string;
    delete?: string;
  };
};

export type OnSubmitReturn<
  DataTypeCreate = any,
  DataTypeUpdate = any,
  DataTypeDelete = any,
> = {
  action?: SubmitAction;
  responseData?: {
    create?: DataTypeCreate;
    delete?: any;
    update?: DataTypeUpdate;
  };
  error?: {
    create?: Error;
    delete?: Error;
    update?: Error;
  };
  status: SubmitStatus;
};

export type PropsSubmit<
  DataTypeCreate = any,
  DataTypeUpdate = any,
  DataTypeDelete = any,
> = {
  action?: SubmitAction;
  data?: OnSubmitData<DataTypeCreate, DataTypeUpdate, DataTypeDelete>;
  dataId?: string;
};

export type OnSubmit<
  DataTypeCreate = any,
  DataTypeUpdate = any,
  DataTypeDelete = any,
> = (
  propsSubmit?: PropsSubmit<DataTypeCreate, DataTypeUpdate, DataTypeDelete>,
) => Promise<OnSubmitReturn<DataTypeCreate, DataTypeUpdate, DataTypeDelete>>;

const isSubmit = (obj: any) => {
  return (
    obj !== undefined &&
    !_.isEqual(obj, "") &&
    !_.isEqual(obj, []) &&
    !_.isEqual(obj, {})
  );
};

const useOnSubmit = <
  DataTypeCreate = any,
  DataTypeUpdate = any,
  DataTypeDelete = any,
>(
  props: UseOnSubmit<DataTypeCreate, DataTypeUpdate, DataTypeDelete>,
) => {
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { submitFiles } = useOnSubmitFiles({
    collectionObjectId: props.dataId,
  });

  const onSubmit: OnSubmit = async (propsSubmit) => {
    const data = propsSubmit?.data || props.data || { update: {} };

    let successes = [];
    let failures = [];

    const isCreate = propsSubmit?.action
      ? propsSubmit?.action === "create"
      : isSubmit(data.create);
    const isDelete = propsSubmit?.action
      ? propsSubmit?.action === "delete"
      : isSubmit(data.delete);
    const isUpdate = propsSubmit?.action
      ? propsSubmit?.action === "update"
      : isSubmit(data.update);

    let createResData: DataTypeCreate | undefined = undefined;
    let updateResData: DataTypeUpdate | undefined = undefined;
    let deleteResData: any = undefined;
    let createError: any | undefined = undefined;
    let updateError: any | undefined = undefined;
    let deleteError: any | undefined = undefined;

    try {
      if (props.apiMutations?.create && isCreate) {
        const res = (await props.apiMutations?.create(data.create)) as any;
        createResData = (res?.data?.data || res?.data) as DataTypeCreate;
        if (res.error) {
          createError = res.error;
          failures.push({
            error: res.error,
            msg:
              `${t("Failed to add")} ${t(props.name)} ${t("records")}! ${t(
                "Error",
              )}: ` + parseRestApiErrorMessage(res.error),
          });
        } else {
          successes.push(
            Array.isArray(data.create) && data.create.length > 1
              ? `${t("Created")} ${data.create.length} ${t(props.name)} ${t(
                  "records",
                )}!`
              : `${t("Created")} ${t(props.name)}`,
          );
        }
      }
      if (props.apiMutations?.delete && isDelete) {
        const res = (await props.apiMutations?.delete(data.delete)) as any;
        deleteResData = res?.data?.data || res?.data; // Might need to be something other

        if (res.error) {
          deleteError = res.error;
          failures.push({
            error: res.error,
            msg:
              `${t("Failed to delete")} ${t(props.name)} ${t("records")}! ${t(
                "Error",
              )}: ` + parseRestApiErrorMessage(res.error),
          });
        } else {
          successes.push(
            Array.isArray(data.delete) && data.delete.length > 1
              ? `${t("Deleted")} ${data.delete.length} ${t(props.name)} ${t(
                  "records",
                )}!`
              : `${t("Deleted")} ${t(props.name)}`,
          );
        }
      }

      if (props.apiMutations?.update && isUpdate) {
        // Only possible to upload file on already created entry

        let payload = clone(data.update);

        let files: FileStorageExtra[] = [];
        if (Array.isArray(payload)) {
          for (const entry of data.update) {
            if (entry.files) {
              files = [...files, ...entry.files];
            }
            delete entry.files;
          }
        } else if (payload.files) {
          files = payload.files;
          delete payload.files;
        }

        if (files.length) {
          await submitFiles({ files });
        }

        if (!Array.isArray(payload) && (propsSubmit?.dataId || props.dataId)) {
          payload = {
            ...payload,
            id: propsSubmit?.dataId || props.dataId,
          };
        }

        const res = (await props.apiMutations?.update(payload)) as any;
        updateResData = (res?.data?.data || res?.data) as DataTypeUpdate;

        if (res.error) {
          updateError = res.error;
          failures.push({
            error: res.error,
            msg:
              `${t("Failed to update")} ${t(props.name)} ${t("records")}! ${t(
                "Error",
              )}: ` + parseRestApiErrorMessage(res.error),
          });
        } else {
          successes.push(
            Array.isArray(data.update) && data.update.length > 1
              ? `${t("Updated")} ${data.update.length} ${t(props.name)} ${t(
                  "records",
                )}!`
              : `${t("Updated")} ${t(props.name)}`,
          );
        }
      }
    } catch (error: any) {
      console.error(error);
      failures.push({
        error,
        msg: `Failed: Error: ` + error.message,
      });
    }

    failures.forEach((f) => {
      console.error(f.error);
      enqueueSnackbar(f.msg, {
        variant: "error",
      });
    });

    successes.forEach((s) => {
      enqueueSnackbar(s, {
        variant: "success",
      });
    });

    const action = isCreate ? "create" : isDelete ? "delete" : "update";
    const status = failures.length > 0 ? "failure" : "success";

    const { rerouteUrlOnSuccess } = props;

    if (
      status === "success" &&
      rerouteUrlOnSuccess !== undefined &&
      rerouteUrlOnSuccess[action]
    ) {
      switch (action) {
        case "create":
          if (rerouteUrlOnSuccess.create)
            navigate(
              rerouteUrlOnSuccess.create({ responseData: createResData }),
            );
          break;
        case "update":
          if (rerouteUrlOnSuccess.update) navigate(rerouteUrlOnSuccess.update);
          break;
        case "delete":
          if (rerouteUrlOnSuccess.delete) navigate(rerouteUrlOnSuccess.delete);
          break;
      }
    }

    return {
      action,
      status,
      responseData: {
        create: createResData,
        update: updateResData,
        delete: deleteResData,
      },
      error: {
        create: createError,
        update: updateError,
        delete: deleteError,
      },
    };
  };

  return onSubmit;
};

export default useOnSubmit;
