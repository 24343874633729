/**
 *Created by Mikael Lindahl on 2023-11-28
 */

import useMUIDataTableFilterStates, {
  AdditionalFilter,
  BaseFilter,
} from "./useMUIDataTableFilterStates";
import { filterToParams } from "../components/Complex/Tables/Invoices/utils";
import {
  FilterOptions,
  SubTableFunction,
  useMUIDataTableOptions,
} from "./useMUIDataTableOptions";
import { MUIDataTableOptions } from "mui-datatables";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import useTranslationWrapper from "./useTranslationWrapper";

// Define the structure of your response
export interface QueryResponse<T> {
  data?: T[];
  metadata?: any; // Or a more specific type for metadata
}

export interface QueryResponseSingle<T> {
  data?: Partial<T>;
  metadata?: any; // Or a more specific type for metadata
}

// Define the structure of the hook's return value
interface QueryHookReturnValue<T> {
  data?: T;
  error?: unknown | undefined;
  isError: boolean;
  isFetching?: boolean;
  isLoading: boolean;
}

// Define a generic type for query Components that return QueryHookReturnValue<T>
export type UseQueryHook<TData = unknown> = (
  arg: any,
) => QueryHookReturnValue<QueryResponse<TData>>;

export type UseQueryHookSingle<TData = unknown> = (
  arg: any,
) => QueryHookReturnValue<QueryResponseSingle<TData>>;

export type CustomButton = ({
  additionalFilter,
  setAdditionalFilter,
}: {
  additionalFilter?: AdditionalFilter;
  setAdditionalFilter?: (additionalFilter: AdditionalFilter) => void;
}) => JSX.Element;

type PrependDataParam<DataType, F> = F extends (...args: infer P) => infer R
  ? (data: DataType[], ...args: P) => R
  : undefined;

type CustomFilterOptions<T> = Omit<FilterOptions<T>, "isRowSelectable"> & {
  isRowSelectable?: PrependDataParam<T, MUIDataTableOptions["isRowSelectable"]>;
};

export type UseMUIDataTableOptionsWithFiltersProps<T = any> = {
  customButton?: CustomButton;
  dataName?: string;
  defaultPaginationOptions?: number[];
  expandableRowsOnClick?: boolean;
  filterInit?: { base?: BaseFilter; match?: AdditionalFilter };
  filterOptions?: CustomFilterOptions<T>;
  filterTransform?: Record<string, (val: any) => any>;
  isRowExpandable?: PrependDataParam<T, MUIDataTableOptions["isRowExpandable"]>;
  onColumnViewChange?: MUIDataTableOptions["onColumnViewChange"];
  onRowExpansionChange?: (
    currentRowsExpanded: any[],
    allRowsExpanded: any[],
    rowsExpanded?: any[],
  ) => void;
  rowsExpanded?: number[];
  subTableFunction?: SubTableFunction;
  triggerChangeOnFilterInit?: (keyof AdditionalFilter)[];
  useGetDataQuery: UseQueryHook<T>;
};

const useMUIDataTableOptionsServerSide = <T>(
  props: UseMUIDataTableOptionsWithFiltersProps<T>,
) => {
  const { enqueueSnackbar } = useSnackbar();

  const { filterInit } = props;

  const { additionalFilter, baseFilter, params, setAdditionalFilter, ...rest } =
    useMUIDataTableFilterStates({
      filterInit: {
        ...filterInit?.match,
        ...filterInit?.base,
      },
      filterToParams,
    });

  const {
    data: queryResult,
    error,
    isLoading,
    isFetching,
    isError,
  } = props.useGetDataQuery(params || undefined); // Can not be null
  const data = queryResult?.data || [];
  const count = queryResult?.metadata.count || 0;

  let { options } = useMUIDataTableOptions<T>({
    customButton: props.customButton,
    dataFetchResult: { data: queryResult, isLoading },
    defaultPageSizeOptions: props.defaultPaginationOptions,
    expandableRowsOnClick: props.expandableRowsOnClick,
    pagination:
      count >
      (props.defaultPaginationOptions ? props.defaultPaginationOptions[0] : 10),
    serverSide: true,
    filter: true,
    filterStates: {
      additionalFilter,
      baseFilter,
      setAdditionalFilter,
      ...rest,
    },
    filterOptions: props.filterOptions && {
      ...props.filterOptions,
      isRowSelectable: (...params) =>
        props?.filterOptions?.isRowSelectable?.(data, ...params) || false,
    },
    filterTransform: props.filterTransform,
    isRowExpandable: (...params) =>
      props?.isRowExpandable?.(data, ...params) || false,
    onColumnViewChange: props.onColumnViewChange,
    onRowExpansionChange: props?.onRowExpansionChange,
    rowsExpanded: props?.rowsExpanded,
    subtableFunction: props.subTableFunction,
  });

  const [t] = useTranslationWrapper();

  useEffect(() => {
    if (
      filterInit &&
      props.triggerChangeOnFilterInit &&
      props.triggerChangeOnFilterInit.length > 0
    ) {
      const triggerChange = props.triggerChangeOnFilterInit.some((key) => {
        return (
          JSON.stringify(filterInit?.match?.[key]) !==
          JSON.stringify(additionalFilter?.[key])
        );
      });

      if (triggerChange) {
        setAdditionalFilter(filterInit?.match);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.triggerChangeOnFilterInit, filterInit]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(`${t(`Could not load`)} ${props?.dataName || "data"}`, {
        variant: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return {
    data,
    error,
    isError,
    isFetching,
    isLoading,
    options,
  };
};
export default useMUIDataTableOptionsServerSide;
