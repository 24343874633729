import ModalWrapper from "src/components/Basic/Mixed/Modals/ModalWrapper";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import sort from "src/utils/sort";
import useTranslation from "src/hooks/useTranslationWrapper";
import { Button } from "@mui/material";
import { RootState } from "src/redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  checkIfPlaceHolderOrEmpty,
  toData,
  TranslationExtra,
} from "../TranslationsStructure";
import {
  deleteMissingTranslations,
  MissingTranslationsState,
} from "src/redux/slices/missingTranslations";

interface MissingModalProps {
  handleCloseModal: () => void;
  formData: TranslationExtra[];
  openModal: boolean;
  setFormData: (data: TranslationExtra[]) => void;
}

export default function TranslationMissingModal(props: MissingModalProps) {
  const [t] = useTranslation();
  const [missingAdded, setMissingAdded] = useState(false);

  const missingTranslations = useSelector<RootState, MissingTranslationsState>(
    ({ missingTranslations }) => missingTranslations,
  );

  const nestedTranslations = missingTranslations.filter((m) =>
    checkIfPlaceHolderOrEmpty(m.key),
  );

  const dispatch = useDispatch();

  return (
    <ModalWrapper
      handleCloseModal={() => props.handleCloseModal()}
      openModal={props.openModal}
      label={"Missing translations"}
      subLabel={
        missingAdded
          ? "Missing translations have been added, please cancel the current translation updates to enable buttons again."
          : "Manage missing translations. Missing translations are picked up when rendering views that lacks translations"
      }
      subLabelType={missingAdded ? "error" : "info"}
    >
      <SectionWithButtonContainer buttonCenter={true}>
        <Button
          key={"remove-nested"}
          color="primary"
          variant="contained"
          sx={{ marginRight: 1 }}
          disabled={nestedTranslations.length === 0}
          onClick={() => {
            dispatch(
              deleteMissingTranslations(
                nestedTranslations.map(({ key, namespace }) => ({
                  key,
                  namespace,
                })),
              ),
            );
          }}
        >
          {t("Remove nested keys") +
            (nestedTranslations.length > 0
              ? ` (${nestedTranslations.length})`
              : "")}
        </Button>
        <Button
          key={"clear-missing"}
          color="primary"
          variant="contained"
          sx={{ marginRight: 1 }}
          disabled={missingTranslations.length === 0 || missingAdded}
          onClick={() => {
            dispatch(
              deleteMissingTranslations(
                missingTranslations.map(({ key, namespace }) => ({
                  key,
                  namespace,
                })),
              ),
            );
          }}
        >
          {t("Clear missing") +
            (missingTranslations.length > 0
              ? ` (${missingTranslations.length})`
              : "")}
        </Button>
        <Button
          key={"add-missing"}
          color="primary"
          variant="contained"
          sx={{ marginRight: 1 }}
          disabled={missingTranslations.length === 0 || missingAdded}
          onClick={() => {
            const addedData = toData({
              data: missingTranslations,
              status: "Added",
            });

            let newFormData = [...addedData, ...props.formData];
            newFormData = sort(
              newFormData,
              (d) => d.key.toLowerCase() + d.namespace,
            );

            props.setFormData(newFormData);
            setMissingAdded(true);
            props.handleCloseModal();
          }}
        >
          {t("Add missing to table") +
            (missingTranslations.length > 0
              ? ` (${missingTranslations.length})`
              : "")}
        </Button>
        <Button
          onClick={() => props.handleCloseModal()}
          variant="outlined"
          sx={{ minWidth: "112px" }}
        >
          {t("Close")}
        </Button>
      </SectionWithButtonContainer>
    </ModalWrapper>
  );
}
