import React, { createContext, ReactNode } from "react";
import { MicrosoftDriveService } from "./Lib/MicrosoftDriveService";
import { useGetCompanyConfigQuery } from "../../redux/services/CompanyConfigsService";
import { LinearProgress } from "@mui/material";
import templateTagsContractOffer from "./Data/templateTagsContractOffer";
import clone from "src/utils/clone";
import useTranslationWrapper from "src/hooks/useTranslationWrapper";
// Define the context type
export const MicrosoftDriveContext = createContext<{
  microsoftDriveClient: MicrosoftDriveService | null;
  isMicrosoftDriveClientLocked: boolean;
  setIsMicrosoftDriveClientLocked: (isLocked: boolean) => void;
}>({
  microsoftDriveClient: null,
  isMicrosoftDriveClientLocked: false,
  setIsMicrosoftDriveClientLocked: () => {},
});

// Context provider component with types
interface MicrosoftDriveProviderProps {
  children: ReactNode;
}

export const MicrosoftDriveProvider: React.FC<MicrosoftDriveProviderProps> = ({
  children,
}) => {
  const [t] = useTranslationWrapper();
  const [isLocked, setIsLocked] = React.useState(false);
  let { data: companyConfigQuery, isLoading } =
    useGetCompanyConfigQuery(undefined);

  let microsoftDriveClient: MicrosoftDriveService | null = null;
  if (companyConfigQuery?.data?.drive?.type === "microsoft") {
    const config = clone(companyConfigQuery.data.drive.config);
    if (!config?.template) {
      config.template = {
        contractOffer: templateTagsContractOffer,
      };
    }

    microsoftDriveClient = new MicrosoftDriveService({
      config,
      t,
      setIsLocked,
    });
  }

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <MicrosoftDriveContext.Provider
      value={{
        microsoftDriveClient,
        isMicrosoftDriveClientLocked: isLocked,
        setIsMicrosoftDriveClientLocked: setIsLocked,
      }}
    >
      {children}
    </MicrosoftDriveContext.Provider>
  );
};
