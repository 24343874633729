import { Company } from "src/accurasee-backend-types/app/company/company.types";
import { Grid } from "@mui/material";
import { useGetCompanySuperAdminQuery } from "src/redux/services/CompanyService";
import { useState } from "react";
import ConfigurationsCompanyFilter from "./ConfigurationsCompanyFilter";
import ConfigurationsDetail from "./ConfigurationsDetail";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionForTableWithToolbar from "src/components/Basic/Simple/Sections/SectionForTableWithToolbar";

const Configurations = () => {
  const { data: companiesQuery, isLoading: isLoadingCompanies } =
    useGetCompanySuperAdminQuery(undefined);
  const companies = companiesQuery?.data || [];

  const [selectedCopmpany, setSelectedCopmpany] = useState<Company>({
    _id: "",
  } as unknown as Company);

  return (
    <SectionContainer>
      <SectionForTableWithToolbar isLoading={isLoadingCompanies}>
        <ConfigurationsCompanyFilter
          companies={companies}
          selectedCopmpany={selectedCopmpany}
          setFilterData={setSelectedCopmpany}
        />
        {selectedCopmpany?._id ? (
          <Grid item xs={12}>
            <ConfigurationsDetail companyId={String(selectedCopmpany?._id)} />
          </Grid>
        ) : (
          <></>
        )}
      </SectionForTableWithToolbar>
    </SectionContainer>
  );
};

export default Configurations;
