import company from "./company";
import developerState, { DeveloperState } from "../slices/developerState";
import dialog from "./dialog";
import dimension from "./dimension";
import file from "./file";
import sidebarState, { SidebarState } from "../slices/sidebarState";
import user from "./user";
import { ReturnUser } from "../../accurasee-backend-types/app/user/user.types";
import { backendApi } from "../api";
import { combineReducers } from "redux";
import missingTranslations, {
  MissingTranslationsState,
} from "../slices/missingTranslations";
import userState, { UserState } from "../slices/userState";

export type RootState = {
  [backendApi.reducerPath]: any;
  user: {
    user: Partial<ReturnUser> | null;
    token: string | null;
    isAuth: boolean;
    isInitialized: boolean;
  };
  company: any;
  file: any;
  developerState: DeveloperState;
  dimension: any;
  dialog: any;
  missingTranslations: MissingTranslationsState;
  sidebarState: SidebarState;
  userState: UserState;
};

export default combineReducers<RootState>({
  // Add the generated reducer as a specific top-level slice
  [backendApi.reducerPath]: backendApi.reducer,
  company,
  developerState,
  dialog,
  dimension,
  file,
  missingTranslations,
  sidebarState,
  user,
  userState,
});
