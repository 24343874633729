/**
 *Created by Mikael Lindahl on 2023-03-13
 */

import { AdapterDateFns as DateAdapter } from "@mui/x-date-pickers/AdapterDateFns";
import { Box, Grid, LinearProgress } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { SxProps } from "@mui/system/styleFunctionSx";
import { Theme, ThemeProvider } from "@mui/material/styles";
import { UseMUIDataTableFilterStatesReturn } from "src/hooks/useMUIDataTableFilterStates";
import clone from "src/utils/clone";
import DraggableDataTable from "./DraggableDataTable";
import getDefaultPersistTableId from "src/utils/getDefaultPersistTableId";
import jsSHA from "jssha";
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableProps,
} from "mui-datatables";
import styles from "./MuiDataTableWrapperStyles";
import usePersistColumnVisibility from "src/hooks/usePersistColumnVisibility";
import usePersistRowsPerPage from "src/hooks/usePersistRowsPerPage";
import usePersistTableFilter from "src/hooks/usePersistTableFilter";
import useMakeTableScrollableOnOverflow from "src/components/Basic/Mixed/Tables/hooks/useMakeTableScrollableOnOverflow";

export type MuiDataTableWrapperColumnDef =
  | string
  | (MUIDataTableColumn & { sum?: number });

export type MuiDataTableWrapperOptions = MUIDataTableProps["options"] & {
  onChangeAdditionalFilter?: UseMUIDataTableFilterStatesReturn["setAdditionalFilter"];
};

const MuiDataTableWrapper = ({
  columns: columnsIn,
  draggable,
  fontSizeCells,
  nameSpace,
  lessPadding,
  isFetching,
  isLoading,
  tableType,
  marginTop,
  options,
  title,
  titlePosition = "middle",
  disabled,
  noBackground,
  setData,
  ...rest
}: Omit<MUIDataTableProps, "title" | "columns"> & {
  columns: MuiDataTableWrapperColumnDef[];
  draggable?: boolean;
  fontSizeCells?: string;
  nameSpace?: string;
  lessPadding?: boolean;
  isFetching?: boolean;
  isLoading?: boolean;
  marginTop?: string;
  options?: MuiDataTableWrapperOptions;
  setData?: (v: any) => void;
  tableType?:
    | "compact"
    | "main-with-filter"
    | "main"
    | "main-nested-level-1-centered"
    | "main-nested-level-1"
    | "main-nested-level-2"; // nested subtable level 2 - not contain subtable
  title?: string;
  titlePosition?: "middle" | "top";
  disabled?: boolean;
  noBackground?: boolean;
}) => {
  const { isTableScrollable, tableRef } = useMakeTableScrollableOnOverflow();

  // Remove columns that are not displayed
  const columns = clone(columnsIn).filter((c) =>
    typeof c !== "string" ? !!c?.options?.display : true,
  );

  const columnNames = getDefaultPersistTableId({ columns });

  const shaObj = new jsSHA("SHA-256", "TEXT");
  shaObj.update(columnNames.join(""));
  const id = shaObj.getHash("HEX");

  const { addColumnVisibility, onColumnViewChange } =
    usePersistColumnVisibility({
      columnNames,
      id,
    });

  const { onChangeRowsPerPage } = usePersistRowsPerPage({
    id,
    onChangeRowsPerPage: options?.onChangeRowsPerPage,
  });
  const { onFilterChange, onSetFilterList } = usePersistTableFilter({
    id,
    onFilterChange: options?.onFilterChange,
    onChangeAdditionalFilter: options?.onChangeAdditionalFilter,
  });

  addColumnVisibility(columns);

  options = options || {};
  options.onColumnViewChange = onColumnViewChange;

  // Handling for table settings from userState NOT applied for nested table
  if (!tableType?.includes("nested")) {
    onSetFilterList(columns);
    options.onFilterChange = onFilterChange;
    options.onChangeRowsPerPage = onChangeRowsPerPage;
  }

  let theme: (baseTheme: any) => Theme;
  switch (tableType) {
    case "compact":
      theme = styles.muiDataCompactTable({ fontSizeCells, isTableScrollable });
      break;
    case "main-with-filter":
      theme = styles.muiDataTableMainWithFilters({
        fontSizeCells,
        disabled,
        isTableScrollable,
      });
      break;
    case "main":
      theme = styles.muiDataTableMain({
        fontSizeCells,
      });
      break;
    case "main-nested-level-1-centered":
      theme = styles.muiDataTableNested({
        align: "center",
        fontSizeCells,
        titlePosition,
      });
      break;
    case "main-nested-level-1":
      theme = styles.muiDataTableNested({
        fontSizeCells,
        titlePosition,
      });
      break;
    case "main-nested-level-2":
      theme = styles.muiDataTableNested({
        fontSizeCells,
        fontStyle: "italic",
        hasBackground: true,
      });
      break;
    default:
      theme = styles.muiDataTableMain({
        fontSizeCells,
      });
      break;
  }

  let sx: SxProps = {
    width: "100%",
    position: "relative",
    overflow: "auto",
    whiteSpace: "nowrap",

    backgroundColor: noBackground ? "transparent" : "var(--white-primary)",
    "& .MuiInput-root": {
      // this is for the input search
      backgroundColor: noBackground ? "transparent" : "var(--white-primary)",
      fontSize: "14px",
    },
  };

  if (marginTop) {
    sx.marginTop = marginTop;
  }

  if (options?.onRowClick) {
    sx = {
      ...sx,
      "& .MuiTableRow-root:hover": {
        cursor: "pointer",
      },
    };
  }

  return (
    <Grid item xs={12} paddingTop={0}>
      {isLoading ? (
        <Box
          width={"100%"}
          sx={{ height: "3rem", paddingTop: "3rem", paddingX: "1rem" }}
        >
          <LinearProgress />
        </Box>
      ) : (
        <LocalizationProvider dateAdapter={DateAdapter}>
          {isFetching && (
            <Box
              width={"100%"}
              sx={{ height: "3rem", paddingTop: "3rem", paddingX: "1rem" }}
            >
              <LinearProgress />
            </Box>
          )}
          <ThemeProvider theme={theme}>
            <Box className={nameSpace || ""} sx={sx} ref={tableRef}>
              {draggable && setData ? (
                <DraggableDataTable
                  columns={columns as MUIDataTableColumn[]}
                  setData={setData}
                  {...rest}
                  title={title}
                />
              ) : (
                <MUIDataTable
                  columns={columns}
                  options={{
                    ...options,
                  }}
                  {...rest}
                  title={title || ""}
                />
              )}
            </Box>
          </ThemeProvider>
        </LocalizationProvider>
      )}
    </Grid>
  );
};

export default MuiDataTableWrapper;
