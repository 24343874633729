/**
 *Created by Mikael Lindahl on 2023-05-09
 */

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { OverridableStringUnion } from "@mui/types";
import {
  ButtonPropsColorOverrides,
  ButtonPropsVariantOverrides,
} from "@mui/material/Button/Button";
import { SxProps } from "@mui/material";
import React from "react";

type ArrayOfArrays = any[][];

type ArrayOfObjects = Record<string, any>[];

type ExportData = ArrayOfArrays | ArrayOfObjects;

type ExportButtonTProps = {
  color?: OverridableStringUnion<
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning",
    ButtonPropsColorOverrides
  >;
  children: React.ReactNode;
  variant?: OverridableStringUnion<
    "text" | "outlined" | "contained",
    ButtonPropsVariantOverrides
  >;
  data: ExportData | (() => ExportData);
  delimiter?: string;
  fileName: string;
  fileType: "csv" | "json" | "xlsx";
  buttonType?: "button" | "icon";
  sx?: SxProps;
  onDone?: () => void;
};

const isArrayOfArrays = (data: ExportData): data is ArrayOfArrays => {
  return Array.isArray(data[0]);
};

const ExportButton = ({
  buttonType = "button",
  ...props
}: ExportButtonTProps) => {
  const delimiter = props.delimiter || ",";

  let handleExport: () => void = () => {};

  const getData = () =>
    typeof props.data === "function" ? props.data() : props.data;

  switch (props.fileType) {
    case "csv":
      handleExport = () => {
        const data = getData();
        let csv = "";

        if (isArrayOfArrays(data)) {
          csv = data.map((row) => row.join(delimiter)).join("\n");
        } else {
          const header = Object.keys(data[0]).join(delimiter);
          const rows = data.map((row) => Object.values(row).join(delimiter));

          csv = [header, ...rows].join("\n");
        }

        const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
        saveAs(blob, props.fileName);
      };
      break;
    case "json":
      handleExport = () => {
        const json = JSON.stringify(getData(), null, 2); // Format JSON with 2-space indentation
        const blob = new Blob([json], { type: "application/json" });
        saveAs(blob, props.fileName);
      };
      break;
    case "xlsx":
      handleExport = () => {
        const data = getData();

        // Create a new worksheet
        const worksheet = isArrayOfArrays(data)
          ? XLSX.utils.aoa_to_sheet(data)
          : XLSX.utils.json_to_sheet(data);

        // Create a new workbook
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        const workbookOutput = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });

        // Save the file using FileSaver.js
        const blob = new Blob([workbookOutput], {
          type: "application/octet-stream",
        });
        saveAs(blob, props.fileName);
      };
      break;
  }

  const commonButtonProps = {
    sx: props.sx,
    onClick: () => {
      handleExport();
      if (props.onDone !== undefined) {
        props.onDone();
      }
    },
  };

  return buttonType === "icon" ? (
    <IconButton {...commonButtonProps}>{props.children}</IconButton>
  ) : (
    <Button
      color={props.color || "primary"}
      component="span"
      variant={props.variant || "outlined"}
      {...commonButtonProps}
    >
      {props.children}
    </Button>
  );
};

export default ExportButton;
