import { PlannedInvoiceGetList } from "src/accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";
import ButtonPrimary from "src/components/Basic/Simple/Buttons/ButtonPrimary";
import useInvoiceStateChange from "src/hooks/useInvoiceStateChange";
import useTranslation from "src/hooks/useTranslationWrapper";
import { CO_INVOICE_ALLOWED_SAME_VALUES } from "../../../../accurasee-backend-types/shared/constants/general";
import { Types } from "mongoose";

type ButtonCoinvoiceTProps = {
  onPostMerge: () => void;
  selectedRows: PlannedInvoiceGetList[];
  setErrorCoinvoice: (v: string) => void;
};

const ButtonCoinvoice = ({
  onPostMerge,
  selectedRows,
  setErrorCoinvoice,
}: ButtonCoinvoiceTProps) => {
  const [t] = useTranslation();

  const selectedPlannedInvoiceIds = selectedRows
    .map((row) => row._id)
    .filter((id): id is Types.ObjectId => Boolean(id));

  const { onInvoiceStateChange } = useInvoiceStateChange({
    selectedPlannedInvoiceIds,
  });

  return (
    <ButtonPrimary
      disabled={selectedRows.length === 0}
      onClick={async () => {
        // Check if all rows has the same

        const first = selectedRows[0];
        const notSameCustomers = selectedRows.some(
          (row) => row.customerId !== first.customerId,
        );

        const notSameRemarks = selectedRows.some((row) => {
          const bothInAllowedValues =
            CO_INVOICE_ALLOWED_SAME_VALUES.includes(first.remarks) &&
            CO_INVOICE_ALLOWED_SAME_VALUES.includes(row.remarks);
          return !bothInAllowedValues && row.remarks !== first.remarks;
        });

        const notSameOrderNumber = selectedRows.some((row) => {
          const bothInAllowedValues =
            CO_INVOICE_ALLOWED_SAME_VALUES.includes(first.yourOrderNumber) &&
            CO_INVOICE_ALLOWED_SAME_VALUES.includes(row.yourOrderNumber);
          return (
            !bothInAllowedValues &&
            row.yourOrderNumber !== first.yourOrderNumber
          );
        });

        const notSameYourReference = selectedRows.some((row) => {
          const bothInAllowedValues =
            CO_INVOICE_ALLOWED_SAME_VALUES.includes(first.yourReference) &&
            CO_INVOICE_ALLOWED_SAME_VALUES.includes(row.yourReference);
          return (
            !bothInAllowedValues && row.yourReference !== first.yourReference
          );
        });

        let msg = "";
        if (
          notSameCustomers ||
          notSameRemarks ||
          notSameOrderNumber ||
          notSameYourReference
        ) {
          msg = `${t("To co-invoice invoices they must have the same")} `;
        }

        if (notSameCustomers) {
          msg += `${t("customer")}, `;
        }
        if (notSameRemarks) {
          msg += `${t("remarks")}, `;
        }

        if (notSameOrderNumber) {
          msg += `${t("order number")}, `;
        }
        if (notSameYourReference) {
          msg += `${t("your reference")}, `;
        }
        if (msg) {
          msg = msg.slice(0, -2);
          setErrorCoinvoice(msg);
        } else {
          try {
            await onInvoiceStateChange({
              onPostMerge,
            });
          } catch (e) {
            console.error(e);
          }
        }
      }}
    >
      {selectedRows.length > 1 ? t("Co-invoice") : t("Invoice")}
    </ButtonPrimary>
  );
};

export default ButtonCoinvoice;
