import { MUIDataTableColumnDef } from "mui-datatables";
import { useGetArticlesQuery } from "src/redux/services/ArticleService";
import { useGetContractQuery } from "src/redux/services/ContractService";
import { useGetLinkedInvoiceDataGroupedByPlannedInvoiceRowIdListQuery } from "src/redux/services/LinkedInvoiceDataService";

import useFormTable from "src/hooks/useFormTable";

import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import Page from "src/components/Basic/Mixed/Pages/Page";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";

import getStructure from "./LinkedInvoiceDataStructure";
import { LinkedInvoiceGetListGroupedByPlannedInvoiceRowId } from "src/accurasee-backend-types/app/linkedInvoiceData/linkedInvoiceData.types";
import { useGetPlannedInvoicesQuery } from "src/redux/services/PlannedinvoiceService";
import LinkedInvoiceDataRowsSubtable from "./LinkedInvoiceDataRowsSubtable";
import { Contract } from "src/accurasee-backend-types/app/contracts/contract.types";
import useMUIDataTableOptionsServerSide from "../../../hooks/useMUIDataTableOptionsServerSide";
import { findById } from "./utils";
import { escape } from "../../../utils/translate";

interface LinkedInvoiceTableProps {
  contractId?: string;
  contracts?: Contract[];
  useColumns: string[];
}

const LinkedInvoiceDataTable = ({
  contractId,
  contracts,
  useColumns,
}: LinkedInvoiceTableProps) => {
  const { data: contract, isLoading: isLoadingContract } = useGetContractQuery(
    contractId,
    {
      skip: contractId === undefined,
    },
  );
  const { data: plannedInvoiceQueryResult } = useGetPlannedInvoicesQuery({
    contractId,
  });
  const plannedInvoices = plannedInvoiceQueryResult?.data || [];

  const { data: articlesQueryResult, isLoading: isLoadingArticles } =
    useGetArticlesQuery(undefined);
  const articles = articlesQueryResult?.data || [];

  const {
    options,
    data: invoiceData,
    isLoading,
  } = useMUIDataTableOptionsServerSide<LinkedInvoiceGetListGroupedByPlannedInvoiceRowId>(
    {
      filterInit: {
        base: {
          sortBy: "createdAt",
          sort: "desc",
        },
        match: {
          contractId,
        },
      },
      subTableFunction: (rowData: any, rowMeta: any) => {
        const invoiceDataSubtable = invoiceData[rowMeta.dataIndex];

        return (
          <LinkedInvoiceDataRowsSubtable
            contract={
              contract || findById(contracts, invoiceDataSubtable.contractId)
            }
            invoiceDataRows={invoiceDataSubtable.linkedInvoiceData}
          />
        );
      },
      triggerChangeOnFilterInit: ["contractId"],
      useGetDataQuery:
        useGetLinkedInvoiceDataGroupedByPlannedInvoiceRowIdListQuery,
    },
  );

  const { structure } =
    useFormTable<LinkedInvoiceGetListGroupedByPlannedInvoiceRowId>({
      initialFormData: invoiceData,
      getStructure,
      extraProps: { articles, plannedInvoices, contracts },
    });

  let columns: MUIDataTableColumnDef[] = structure.items
    .filter((item) => !(item.dataName === "projectExternalId" && contractId)) // Remove contract column on contract view
    .map((item) =>
      BuilderColumnOptionsSingle<LinkedInvoiceGetListGroupedByPlannedInvoiceRowId>(
        {
          data: invoiceData,
          item,
        },
      ),
    )
    .filter((column: any) => useColumns?.includes(column.name));

  const breadcrumbs = contractId
    ? [
        { label: "Contracts", link: "/app/contracts" },
        {
          label: `Contract${escape(` ${contract?.projectExternalId || ""}`)}`,
          link: `/app/contracts/${contractId}`,
        },
        {
          label: "Linked invoice data",
        },
      ]
    : [{ label: "Operations" }, { label: "Linked invoice data" }];

  return (
    <Page
      label={"Linked invoice data"}
      breadcrumbs={breadcrumbs}
      isLoading={isLoadingContract || isLoadingArticles}
    >
      <MuiDataTableWrapper
        data={invoiceData}
        columns={columns}
        isFetching={isLoading}
        options={options}
        title=""
        tableType="main-with-filter"
        noBackground={true}
      />
    </Page>
  );
};

export default LinkedInvoiceDataTable;
