import { ArticleWithTotals } from "src/accurasee-backend-types/app/article/article.types";
import { getStructure, GetStructureProps } from "./ArticlesTableStructure";
import { MUIDataTableColumnDef } from "mui-datatables";
import { unitApi } from "src/redux/services/UnitService";
import { useGetArticlesTableQuery } from "src/redux/services/ArticleService";
import { useGetCompanyQuery } from "src/redux/services/CompanyService";
import { useLocation } from "react-router-dom";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import useFormTable from "src/hooks/useFormTable";
import useTranslation from "src/hooks/useTranslationWrapper";
import useMUIDataTableOptionsServerSide from "src/hooks/useMUIDataTableOptionsServerSide";

const ArticlesTable = () => {
  const [t] = useTranslation();
  const location = useLocation();

  const { data: queryResultCompany, isLoading: isLoadingCompany } =
    useGetCompanyQuery(undefined);

  const company = queryResultCompany?.company;

  const { data: units, isLoading: isLoadingUnits } =
    unitApi.useGetUnitsQuery(undefined);

  const {
    options,
    data: articles,
    isLoading: isLoadingArticles,
    isFetching,
  } = useMUIDataTableOptionsServerSide<ArticleWithTotals>({
    dataName: "customers",
    defaultPaginationOptions: [10, 15, 50],
    filterInit: {
      base: {
        pageSize: 15,
        sort: "asc",
        sortBy: "number",
      },
      match: { active: true },
    },
    filterOptions: {
      selectableRows: "none",
      searchPlaceholder: `${t("Search for")} ${t("Name").toLowerCase()} ${t(
        "or",
      )} ${t("Number").toLowerCase()}...`,
    },
    filterTransform: {
      active: (values: string[]) => !values.find((v) => v === "Show inactive"),
    },
    useGetDataQuery: useGetArticlesTableQuery,
  });

  const { formData, helperText, structure } = useFormTable<
    ArticleWithTotals,
    GetStructureProps
  >({
    extraProps: {
      company,
      units,
      location,
    },
    initialFormData: articles,
    getStructure,
  });

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle<ArticleWithTotals>({
      data: formData,
      helperText,
      item,
      disabled: true, // table is now readonly
    }),
  );

  return (
    <MuiDataTableWrapper
      title={""}
      data={formData || []}
      columns={columns}
      isFetching={isFetching}
      isLoading={isLoadingArticles || isLoadingUnits || isLoadingCompany}
      options={options}
      tableType="main-with-filter"
      noBackground={true}
    />
  );
};
export default ArticlesTable;
