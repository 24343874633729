/**
 *Created by Mikael Lindahl on 2023-01-30
 */

import { Article } from "src/accurasee-backend-types/app/article/article.types";
import { ContractArticle } from "src/accurasee-backend-types/app/contracttype/contracttype.types";
import { FormGlue } from "src/hooks/useFormGlue";
import {
  getStructure,
  GetStructureExtraProps,
} from "./ContractArticlesGetStructure";
import { Grid, Typography } from "@mui/material";
import { MUIDataTableColumnDef } from "mui-datatables";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useMUIDataTableOptions } from "src/hooks/useMUIDataTableOptions";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Box from "@mui/material/Box";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import ButtonPrimary from "src/components/Basic/Simple/Buttons/ButtonPrimary";
import Collapse from "@mui/material/Collapse";
import ContractArticlePriceChangeModal from "src/components/Complex/Modals/ContractArticlePriceChangeModal";
import getMissingDataMessage from "src/utils/getMissingDataMessage";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import Section from "src/components/Basic/Simple/Sections/Section";
import useFormTable from "src/hooks/useFormTable";
import useStyles from "./ContractArticlesStyles";
import useTranslation from "src/hooks/useTranslationWrapper";

type ContractArticlesTProps = {
  articleList: Article[] | undefined;
  contractArticles?: ContractArticle[];
  contractArticlesInit?: ContractArticle[];
  contractId?: string;
  currencyCode?: string;
  disabled?: boolean;
  editRights: boolean;
  expandedArticles?: boolean;
  formGlue?: FormGlue;
  handleArticleChange?: (e: any, child: any, index: number) => void;
  handleExpandArticles?: () => void;
  hasTriedToSubmit?: boolean;
  id?: string;
  isArticlesExpanded?: boolean;
  isIndex?: boolean;
  isMilestone?: boolean;
  isView?: boolean;
  label?: string;
  setContractArticles?: (v: ContractArticle[]) => void;
  setIsFormValid?: (isValid: boolean) => void;
  useDownStreamPropagation?: boolean;
  useExpand?: boolean;
};

const ExpandMore = styled((props: any) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(90deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function ContractArticles(props: ContractArticlesTProps) {
  const [t] = useTranslation();
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [expandedArticles, setExpandedArticles] = useState(
    !!props.isArticlesExpanded || !props?.useExpand,
  );

  let countArticles =
    props.articleList?.filter((article) => article.name !== undefined).length ||
    0;

  const [articleIdsUsed, setArticleIdsUsed] = useState<string[]>([]);

  const { formData, helperText, setHelperText, setFormData, structure } =
    useFormTable<ContractArticle, GetStructureExtraProps>({
      extraProps: {
        articles: props.articleList,
        articleIdsUsed,
        currencyCode: props.currencyCode,
        isIndex: props.isIndex,
        isMilestone: props.isMilestone,
        isView: props.isView,
      },
      formGlue: props?.formGlue,
      initialFormData: props.contractArticlesInit,
      getStructure,
      setIsFormValidExternal: props.setIsFormValid,
    });

  const { setContractArticles } = props;
  useEffect(() => {
    setArticleIdsUsed(formData.map((d) => String(d.articleId)));
    if (setContractArticles) {
      setContractArticles(formData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle<ContractArticle>({
      data: formData,
      item,
      disabled: props.disabled,
      helperText,
    }),
  );

  const { options } = useMUIDataTableOptions({
    pagination: formData.length >= 10,
  });

  if ((!formData || !formData.length) && props.isView) {
    return (
      <Section label={props.label || "Articles"}>
        {"No contract articles exists ..."}
      </Section>
    );
  }

  return (
    <Section
      label={props.label || "Articles"}
      rightBoxArea={
        <Box component="div" sx={{ height: "50px" }}>
          {props.useExpand && (
            <ExpandMore
              expand={expandedArticles}
              onClick={() => setExpandedArticles(!expandedArticles)}
              aria-expanded={expandedArticles}
              aria-label="show more"
            >
              <KeyboardArrowRightIcon />
            </ExpandMore>
          )}
        </Box>
      }
    >
      <Grid
        key={"contract-article-section"}
        container
        item
        alignItems="center"
        xs={12}
        className={classes.contractArticleSection}
      >
        <Collapse
          className={classes.collapse}
          in={expandedArticles}
          timeout="auto"
          unmountOnExit
        >
          {formData && formData.length !== 0 && (
            <MuiDataTableWrapper
              title={"Articles"}
              data={formData || []}
              columns={columns}
              options={options}
            />
          )}
        </Collapse>
        {countArticles === 0 && (
          <Typography
            sx={{ color: "var(--gray-9)", textAlign: "center", width: "100%" }}
          >
            {getMissingDataMessage("articles", t)}
          </Typography>
        )}
        {!props.isView && formData && (
          <Grid item xs={12} lg={12} sx={{ paddingTop: "2rem" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <IconButton
                disableRipple
                disabled={props.disabled || countArticles === 0}
                color="primary"
                onClick={() => {
                  setHelperText([...helperText, {}]);
                  setFormData([
                    ...formData,
                    {
                      defaultArticle: false,
                      isPricePerUnitEstimate: false,
                      isQuantityEstimate: false,
                      isIndex: props.isIndex,
                    } as ContractArticle,
                  ]);
                }}
                id={props.id || "add-contract-article"}
              >
                <AddCircleIcon fontSize="large" />
                <span style={{ fontSize: "16px", paddingLeft: "7px" }}>
                  {t(`${" Add article"}`)}
                </span>
              </IconButton>
              {props.useDownStreamPropagation && formData?.length > 0 && (
                <ButtonPrimary
                  id={props.id || "add-contract-article"}
                  disabled={!props?.editRights}
                  onClick={() => setOpenModal(true)}
                >
                  {t("Make price/quantity updates")}
                </ButtonPrimary>
              )}
              <ContractArticlePriceChangeModal
                articles={props.articleList || []}
                articleIdsUsed={articleIdsUsed}
                contractArticles={formData}
                contractId={props.contractId || ""}
                openModal={openModal}
                handleCloseModal={() => setOpenModal(false)}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Section>
  );
}
