import getSelectOptions from "../../../../../utils/getSelectOptions";
import { CreateActivityLog } from "../../../../../accurasee-backend-types/app/activity_log/activity_log.types";
import { MicrosoftDocumentWrapper } from "src/hooks/useMicrosoftDrive/useMicrosoftDriveList";
import { MicrosoftDriveService } from "src/context/MicrosoftDriveProvider/Lib/MicrosoftDriveService";
import { Types } from "mongoose";
import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import { useCreateActivityLogMutation } from "../../../../../redux/services/ActivityLogService";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

export type GetStructureExtraProps = {
  groups?: string[];
  invalidate: () => void;
  service: MicrosoftDriveService | null;
  types?: string[];
};

export type ItemType = "main" | "edit";

const useMicrosoftDriveTableStructure = ({
  activityLogGroupId,
  excludeActionRow,
}: {
  activityLogGroupId?: Types.ObjectId;
  excludeActionRow?: boolean;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [createActivityLog] = useCreateActivityLogMutation();

  const userState: any = useSelector((data) => data);

  const actorUserId = userState?.user?.user?._id as Types.ObjectId;

  const getStructure: UserFormTableGetStructure<
    MicrosoftDocumentWrapper,
    GetStructureExtraProps,
    ItemType
  > = ({ extraProps }) => {
    return {
      items: [
        {
          itemType: "main",
          dataName: "",
          getValue: ({ rowIndex }) => rowIndex + 1,
          headerLabel: "#",
          type: "text",
        },
        {
          itemType: "main",
          dataName: "group",
          headerLabel: "Group",
          type: "autocomplete",
          options: getSelectOptions({
            data: extraProps?.groups,
          }),
          setFormDataCustom: ({ value, data, rowIndex }) => {
            const metadata = {
              description: encodeURIComponent(
                JSON.stringify({ group: value, type: data[rowIndex].type }),
              ),
            };

            extraProps?.service
              ?.addMetadata({ id: data[rowIndex].id, metadata })
              .then(() => {
                extraProps?.invalidate();
              });
          },
        },
        {
          itemType: "main",
          dataName: "type",
          headerLabel: "Type",
          type: "autocomplete",
          options: getSelectOptions({ data: extraProps?.types }),
          setFormDataCustom: ({ value, data, rowIndex }) => {
            const metadata = {
              description: encodeURIComponent(
                JSON.stringify({ group: data[rowIndex].group, type: value }),
              ),
            };

            extraProps?.service
              ?.addMetadata({ id: data[rowIndex].id, metadata })
              .then(() => {
                extraProps?.invalidate();
              });
          },
        },
        {
          itemType: "main",
          dataName: "author",
          headerLabel: "Author",
          type: "text",
        },
        {
          itemType: "main",
          dataName: "fileName",
          headerLabel: "Filename",
          type: "link",
          onClick: (props) => {
            const data = props.data[props.rowIndex];
            extraProps?.service?.openURL(data.origin);
          },
        },
        {
          itemType: "main",
          dataName: "created",
          headerLabel: "Created",
          type: "text",
        },
        {
          itemType: "main",
          dataName: "updated",
          headerLabel: "Updated",
          type: "text",
        },
        {
          itemType: "main",
          type: "action_row",
          dataName: "download",
          showCellWhen: () => !excludeActionRow,
          actionRowOptions: [
            {
              elementType: "icon",
              iconType: "download",
              onClick: async (props) => {
                try {
                  const data = props.data[props.rowIndex];
                  await extraProps?.service?.browserTriggerFileDownload(
                    data.origin,
                  );
                } catch (e) {
                  console.error(e);
                  enqueueSnackbar("Failed to download file", {
                    variant: "error",
                  });
                }
              },
            },
            {
              elementType: "icon",
              iconType: "delete",
              useDeleteWithCommentDialog: true,
              onClick: async (props, extra) => {
                try {
                  const data = props.data[props.rowIndex];
                  await extraProps?.service?.deleteFile(data.origin);
                  enqueueSnackbar("File deleted", {
                    variant: "success",
                  });
                  extraProps?.invalidate();

                  if (activityLogGroupId) {
                    const log: CreateActivityLog = {
                      action: "delete",
                      actorUserId,
                      comment: extra?.comment || "",
                      emailRecipient: "",
                      emailSender: "",
                      fileName: data.origin.name as string,
                      groupId: activityLogGroupId,
                      type: "microsoft_drive_file",
                    };

                    console.log(log);

                    await createActivityLog(log);

                    enqueueSnackbar("Log entry added", {
                      variant: "success",
                    });
                  }
                } catch (e) {
                  console.error(e);
                  enqueueSnackbar("Failed to delete file", {
                    variant: "error",
                  });
                }
              },
            },
          ],
        },
      ],
    };
  };

  return getStructure;
};

export default useMicrosoftDriveTableStructure;
