import CustomerInvoices from "src/screens/App/Contracts/[contractId]/Invoices/Customer/CustomerInvoices";
import Page from "src/components/Basic/Mixed/Pages/Page";
import PlannedInvoices from "src/screens/App/Contracts/[contractId]/Invoices/Planned/PlannedInvoices";
import TabWithLinksTransparent from "src/components/Basic/Mixed/Tabs/TabWithLinksTransparent";
import { RoutePath } from "src/accurasee-backend-types/app/general/routes.types";
import { TabsType } from "src/components/Basic/Mixed/Tabs/TabsWithLink";
import { escape } from "src/utils/translate";
import { useGetContractQuery } from "src/redux/services/ContractService";
import { useParams } from "react-router-dom";

const RoutesInvoicesTab = () => {
  const { contractId } = useParams<{ contractId: string }>();

  const { data: contract, isLoading } = useGetContractQuery(contractId, {
    skip: !contractId,
  });

  const url = `/app/contracts/${contractId}/invoices`;
  const path = "/app/contracts/:contractId/invoices";
  const pathToStrip = "/app/contracts/:contractId";
  const redirectPath: RoutePath = "/app/contracts/:contractId";
  const getMainRouteUrl = (path: RoutePath) =>
    contractId ? path.replace(":contractId", contractId) : path;
  const tabs: TabsType[] = [
    {
      name: "Planned invoices",
      mainRoute: {
        component: <PlannedInvoices />,
        path: "/app/contracts/:contractId/invoices/planned",
      },
    },
    {
      name: "Customer invoices",
      mainRoute: {
        component: <CustomerInvoices />,
        path: "/app/contracts/:contractId/invoices/customer",
      },
    },
  ];

  const breadcrumbs = [
    { label: "Contracts", link: "/app/contracts" },
    {
      label: `Contract${escape(` ${contract?.projectExternalId}`)}`,
      link: `/app/contracts/${contractId}`,
    },
    { label: "Invoices" },
  ];
  return (
    <Page label={"Invoices"} breadcrumbs={breadcrumbs} isLoading={isLoading}>
      <TabWithLinksTransparent
        basePath={path}
        baseUrl={url}
        getMainRouteUrl={getMainRouteUrl}
        name="contracts-invoices"
        pathToStrip={pathToStrip}
        redirectPath={redirectPath}
        tabs={tabs}
        isSubTabs
      />
    </Page>
  );
};

export default RoutesInvoicesTab;
