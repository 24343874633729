import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  SidebarState,
  updateSidebarState,
} from "src/redux/slices/sidebarState";
import { Inbox as InboxIcon } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { LocationDescriptorObject } from "history";
import { RootState } from "src/redux/reducers";
import { RoutePath } from "src/accurasee-backend-types/app/general/routes.types";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import classnames from "classnames";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import useStyles from "./SidebarLinkStyles";
import useTranslation from "src/hooks/useTranslationWrapper";

interface LinkItemProps {
  label: string;
  link: RoutePath;
}

interface SidebarLinkProps {
  link?: RoutePath;
  otherLinks?: RoutePath[];
  icon?: JSX.Element;
  label: string;
  children: LinkItemProps[];
  location: LocationDescriptorObject;
  isSidebarHovered: boolean;
  setSidebarHovered?: (v: boolean) => void;
  nested?: boolean;
  type?: string;
  section?: string;
  tooltip?: string;
}

export default function SidebarLink({
  link,
  otherLinks,
  icon,
  label,
  children,
  location,
  isSidebarHovered,
  setSidebarHovered,
  nested,
  type,
  section,
  tooltip,
}: SidebarLinkProps) {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  // global
  const sidebarState = useSelector<RootState, SidebarState>(
    ({ sidebarState }) => sidebarState,
  );

  const isSectionActive = () => {
    if (section) {
      if (section === "contracts") {
        if (location.pathname?.includes("/app/contracts")) {
          return section;
        }
      }
      if (section === "offers") {
        if (location.pathname?.includes("/app/contract-offers")) {
          return section;
        }
      }
      if (section === "products") {
        if (location.pathname?.includes("/app/product-and-plans")) {
          return section;
        }
      }
      if (section === "operations") {
        if (location.pathname?.includes("/app/operations")) {
          return section;
        }
      }
      if (section === "insights") {
        if (location.pathname?.includes("/app/insights")) {
          return section;
        }
      }
      if (section === "setup") {
        if (location.pathname?.includes("/app/setup")) {
          return section;
        }
      }
    }
  };
  // local
  var [isOpen, setIsOpen] = useState(
    sidebarState?.expandedItem.indexOf(label) !== -1,
  );
  const isSidebarOpened = sidebarState.isSidebarOpened || isSidebarHovered;

  var isLinkActive =
    link &&
    (location.pathname === link ||
      (location?.pathname?.includes(link) &&
        location?.pathname?.split(link).length <= 1));

  if (otherLinks && otherLinks.some((link) => link === location.pathname)) {
    isLinkActive = true;
  }

  const toggleCollapse = () => {
    if (isSidebarOpened) {
      setIsOpen(!isOpen);

      // Add label to list of expanded items (if exists then remove)
      let newExpandedItem = [...sidebarState?.expandedItem];
      if (newExpandedItem?.indexOf(label) !== -1) {
        newExpandedItem?.splice(newExpandedItem.indexOf(label), 1);
      } else {
        newExpandedItem = [...newExpandedItem, label];
      }

      dispatch(
        updateSidebarState({
          ...sidebarState,
          expandedItem: newExpandedItem,
        }),
      );
    }
  };

  if (type === "title" && isSidebarOpened)
    return (
      <Typography
        className={classnames(classes.linkText, classes.sectionTitle, {
          [classes.linkTextHidden]: !isSidebarOpened,
        })}
      >
        {t(label)}
      </Typography>
    );

  if (link && link.includes("http")) {
    return (
      <ListItem
        button
        className={classes.link}
        classes={{
          root: classnames({
            [classes.linkActive]: isLinkActive && !nested,
            [classes.linkNested]: nested,
          }),
        }}
        disablePadding
        disableRipple
      >
        <a className={classes.externalLink} href={link}>
          {!nested && (
            <ListItemIcon
              className={classnames(classes.linkIcon, {
                [classes.linkIconActive]: isLinkActive,
              })}
            >
              {icon}
            </ListItemIcon>
          )}
          <ListItemText
            classes={{
              primary: classnames(classes.linkText, {
                [classes.linkTextActive]: isLinkActive,
                [classes.linkTextHidden]: !isSidebarOpened,
              }),
            }}
            primary={t(label)}
          />
        </a>
      </ListItem>
    );
  }

  if (children?.length === 0) {
    return (
      <ListItem
        {...(link ? { component: Link, to: link } : {})}
        className={classes.link}
        disablePadding
        onMouseOver={() => {
          if (!isSidebarOpened && !isSidebarHovered && setSidebarHovered) {
            setSidebarHovered(true);
          }
        }}
      >
        <ListItemButton
          className={classnames(classes.itemButton, {
            [classes.linkActive]: isLinkActive && !nested,
            [classes.linkNested]: nested,
          })}
        >
          {isSidebarOpened && !nested && (
            <ListItemIcon
              className={classnames(classes.linkIcon, {
                [classes.linkIconActive]: isLinkActive,
              })}
            >
              {icon}
            </ListItemIcon>
          )}
          {!isSidebarOpened && !nested && (
            <Tooltip title={tooltip} placement="right" arrow>
              <ListItemIcon
                className={classnames(classes.linkIcon, {
                  [classes.linkIconActive]: isLinkActive,
                })}
              >
                {icon}
              </ListItemIcon>
            </Tooltip>
          )}

          <ListItemText
            classes={{
              root: classes.linkNoMargin,
              primary: classnames(classes.linkText, {
                [classes.linkTextActive]: isLinkActive,
                [classes.linkTextHidden]: !isSidebarOpened,
              }),
            }}
            primary={t(label)}
          />
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <>
      <ListItem
        component={isSidebarOpened ? "div" : Link}
        onClick={toggleCollapse}
        to={link}
        className={classes.link}
        disablePadding
        onMouseOver={() => {
          if (!isSidebarOpened && !isSidebarHovered && setSidebarHovered) {
            setSidebarHovered(true);
          }
        }}
      >
        <ListItemButton
          className={classnames(classes.itemButton, {
            [classes.linkActive]: section === isSectionActive() || isLinkActive,
          })}
        >
          {isSidebarOpened ? (
            <ListItemIcon
              className={classnames(classes.linkIcon, {
                [classes.linkIconActive]:
                  isLinkActive || section === isSectionActive(),
              })}
            >
              {icon ? icon : <InboxIcon />}
            </ListItemIcon>
          ) : (
            <Tooltip title={tooltip} placement="right" arrow>
              <ListItemIcon
                className={classnames(classes.linkIcon, {
                  [classes.linkIconActive]:
                    isLinkActive || section === isSectionActive(),
                })}
              >
                {icon ? icon : <InboxIcon />}
              </ListItemIcon>
            </Tooltip>
          )}
          <ListItemText
            classes={{
              root: classes.linkNoMargin,
              primary: classnames(classes.linkText, {
                [classes.linkTextActive]:
                  section === isSectionActive() || isLinkActive,
                [classes.linkTextHidden]: !isSidebarOpened,
              }),
            }}
            primary={t(label)}
          />
          <KeyboardArrowRightIcon
            className={classnames(classes.expandMore, {
              [classes.expandMoreActive]:
                section === isSectionActive() || isLinkActive,
              [classes.linkTextHidden]: !isSidebarOpened,
              [classes.expandMoreOpen]: isOpen,
            })}
            fontSize="small"
          />
        </ListItemButton>
      </ListItem>
      {children && (
        <Collapse
          in={isOpen && isSidebarOpened}
          timeout="auto"
          unmountOnExit
          className={classes.nestedList}
        >
          <List component="div" disablePadding>
            {children.map((childrenLink) => (
              <SidebarLink
                key={childrenLink && childrenLink.link}
                location={location}
                isSidebarHovered={isSidebarHovered}
                nested
                {...childrenLink}
                children={[]}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}
