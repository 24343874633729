/**
 *Created by Mikael Lindahl on 2023-03-19
 */

import {
  Box,
  Card,
  Container,
  Grid,
  LinearProgress,
  SxProps,
  ThemeProvider,
} from "@mui/material";
import { createTheme, Theme } from "@mui/material/styles";
import styles from "src/themes/default";
import React from "react";
import PageHeading from "src/components/Basic/Mixed/Headings/PageHeading";
import { Breadcrumb } from "src/components/Basic/Mixed/Breadcrumb/BreadcrumbsWrapper";

type CardSectionContainerTProps = {
  children?:
    | JSX.Element
    | (boolean | undefined | JSX.Element)[]
    | boolean
    | undefined;
  label?: string;
  labelSize?: "normal" | "large";
  subLabel?: string;
  isFullWidth?: boolean;
  breadcrumbs?: Breadcrumb[];
  isLoading?: boolean;
  boxRight?: JSX.Element | JSX.Element[];
  sx?: SxProps<Theme> | undefined;
};

const theme = createTheme(styles);

const Page = ({
  children,
  sx,
  isFullWidth,
  isLoading,
  ...rest
}: CardSectionContainerTProps) => {
  const childrenArray = React.Children.toArray(children);

  const content = (
    <ThemeProvider theme={theme}>
      <Card
        sx={{
          padding: "var(--space-16px) 0",
          backgroundColor: "transparent",
          boxShadow: "none",
          ...sx,
        }}
      >
        <PageHeading {...rest} />
        {isLoading ? (
          <Box paddingY={1}>
            <LinearProgress />
          </Box>
        ) : (
          <Grid container spacing={4} marginTop={3}>
            {childrenArray.map((child, index) => {
              return (
                <Grid
                  item
                  style={index === 0 ? { paddingTop: "0px" } : {}}
                  sx={{ width: "100%" }}
                  key={index}
                >
                  {child}
                </Grid>
              );
            })}
          </Grid>
        )}
      </Card>
    </ThemeProvider>
  );
  return !isFullWidth && isFullWidth !== undefined ? (
    <Container>{content}</Container>
  ) : (
    content
  );
};

export default Page;
