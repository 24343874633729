import "./App.css";

import React from "react";
import Routes from "./routes/Routes";
import TranslationProvider from "./context/TranslationProvider";
import { BrowserRouter } from "react-router-dom";

Error.stackTraceLimit = 20;
const App = () => {
  return (
    <BrowserRouter>
      <TranslationProvider>
        <Routes />
      </TranslationProvider>
    </BrowserRouter>
  );
};

export default App;
