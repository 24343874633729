import { RoutePath } from "src/accurasee-backend-types/app/general/routes.types";
import { useGetContractQuery } from "src/redux/services/ContractService";
import { useParams, Routes, Route, Navigate } from "react-router-dom";
import Contract from "src/screens/App/Contracts/[contractId]/Contract/Contract";
import ContractCosts from "src/screens/App/Contracts/[contractId]/Costs/ContractCosts";
import Incomes from "src/screens/App/Contracts/[contractId]/Incomes/Incomes";
// import ContractInsights from "src/screens/App/Contracts/[contractId]/Insights/ContractInsights";
import CustomerInvoice from "src/components/Screens/CustomerInvoiceBase/CustomerInvoiceBase";
import CustomerInvoices from "src/screens/App/Contracts/[contractId]/Invoices/Customer/CustomerInvoices";
import Economy from "src/screens/App/Contracts/[contractId]/Economy/Economy";
import ContractReports from "src/screens/App/Contracts/[contractId]/Reports/ContractReports";
import InvoicePlan from "src/screens/App/Contracts/[contractId]/InvoicePlans/[invoicePlanId]/InvoicePlan";
import InvoicePlans from "src/screens/App/Contracts/[contractId]/InvoicePlans/InvoicePlans";
import LinearProgress from "@mui/material/LinearProgress";
// import LinkedInvoiceData from "src/screens/App/Contracts/[contractId]/LinkedInvoiceData/LinkedInvoiceData";
import NavigationButtonsContract from "src/components/Basic/Mixed/NavigationButtons/NavigationButtonsContract";
import NotFound from "src/components/Screens/NotFound/NotFound";
import PlannedInvoice from "src/components/Screens/PlannedInvoiceBase/PlannedInvoiceBase";
import PlannedInvoices from "src/screens/App/Contracts/[contractId]/Invoices/Planned/PlannedInvoices";
import RoutesInvoicesTab from "src/routes/App/Contracts/[contractId]/Invoices/RoutesInvoicesTab";
import SubcontractContainer from "src/screens/App/Contracts/[contractId]/Subcontracts/[subcontractId]/Subcontract";
import SubcontractsTable from "src/screens/App/Contracts/[contractId]/Subcontracts/Subcontracts";
import SupplierInvoicesTable from "src/screens/App/Contracts/[contractId]/Supplierinvoices/SupplierInvoices";
import TabsWithLink, {
  TabsType,
} from "src/components/Basic/Mixed/Tabs/TabsWithLink";

const RoutesContract = () => {
  let { contractId } = useParams<{ contractId: string }>();
  const {
    data: contract,
    isLoading,
    isError,
  } = useGetContractQuery(contractId, {
    skip: contractId === undefined,
  });
  const isServiceOrderContract =
    !!contract?.contractFeatures?.serviceOrderContract;

  const url = `/app/contracts/${contractId}`;
  const path = "/app/contracts/:contractId";
  const redirectPath: RoutePath = "/app/contracts/:contractId";
  const getMainRouteUrl = (path: RoutePath) =>
    contractId ? path.replace(":contractId", contractId) : path;
  const pathToStrip = "/app/contracts/:contractId";

  // Filter away tabs with no read rights and on contract with contractTypeFeatures?.serviceOrderContract

  const tabs: TabsType[] = [
    {
      name: "Contract",
      mainRoute: {
        component: <Contract />,
        path: "/app/contracts/:contractId",
      },
    },
    {
      name: "Invoice plans",
      mainRoute: {
        component: <InvoicePlans />,
        path: "/app/contracts/:contractId/invoiceplans",
        isHidden: isServiceOrderContract,
      },
      subRoutes: [
        {
          component: <InvoicePlan />,
          exact: false,
          path: "/app/contracts/:contractId/invoiceplans/create",
        },
        {
          component: <InvoicePlan />,
          exact: false,
          path: "/app/contracts/:contractId/invoiceplans/:invoicePlanId",
        },
      ],
    },
    {
      name: "Invoices",
      mainRoute: {
        component: <RoutesInvoicesTab />,
        path: "/app/contracts/:contractId/invoices",
      },
      subRoutes: [
        {
          component: <PlannedInvoice />,
          path: "/app/contracts/:contractId/invoices/planned/:id",
          exact: false,
        },
        {
          component: <CustomerInvoice />,
          path: "/app/contracts/:contractId/invoices/customer/:id",
          exact: false,
        },
      ],
      children: [
        {
          component: <PlannedInvoices />,
          path: "/app/contracts/:contractId/invoices/planned",
        },
        {
          component: <CustomerInvoices />,
          path: "/app/contracts/:contractId/invoices/customer",
        },
      ],
    },
    {
      name: "Supplier invoices",
      mainRoute: {
        component: <SupplierInvoicesTable />,
        path: "/app/contracts/:contractId/supplierinvoices",
      },
    },
    {
      name: "Incomes",
      mainRoute: {
        component: <Incomes />,
        path: "/app/contracts/:contractId/incomes",
      },
    },
    {
      name: "Costs",
      mainRoute: {
        component: <ContractCosts />,
        path: "/app/contracts/:contractId/costs",
      },
    },
    // temporarily hide LinkedInvoiceData and Insights for implentation and updating settings
    // {
    //   name: "Linked invoice data",
    //   mainRoute: {
    //     component: <LinkedInvoiceData />,
    //     path: "/app/contracts/:contractId/linkedinvoicedata",
    //     isHidden: isServiceOrderContract,
    //   },
    // },
    {
      name: "Subcontracts",
      mainRoute: {
        component: <SubcontractsTable />,
        path: "/app/contracts/:contractId/subcontracts",
      },
      subRoutes: [
        {
          component: <SubcontractContainer />,
          exact: false,
          path: "/app/contracts/:contractId/subcontracts/:subcontractId",
        },
      ],
    },
    // {
    //   name: "Insights",
    //   mainRoute: {
    //     component: <ContractInsights />,
    //     path: "/app/contracts/:contractId/insights",
    //     isHidden: true,
    //   },
    // },
    {
      name: "Project economy",
      mainRoute: {
        component: <Economy />,
        path: "/app/contracts/:contractId/economy",
        isHidden: isServiceOrderContract,
      },
    },
    {
      name: "Reports",
      mainRoute: {
        component: <ContractReports />,
        path: "/app/contracts/:contractId/report",
        isHidden: isServiceOrderContract,
      },
    },
  ];

  if (isLoading) {
    return <LinearProgress />;
  }

  if (isError) {
    return <NotFound type="contract_list" />;
  }

  return (
    <>
      <TabsWithLink
        basePath={path}
        baseUrl={url}
        getMainRouteUrl={getMainRouteUrl}
        name={"contract"}
        navigationButtons={<NavigationButtonsContract />}
        pathToStrip={pathToStrip}
        redirectPath={redirectPath}
        tabs={tabs}
        noIndicator
      />
      <Routes>
        <Route
          path="/invoices"
          element={
            <Navigate
              to={getMainRouteUrl(
                "/app/contracts/:contractId/invoices/planned",
              )}
            />
          }
        />
      </Routes>
    </>
  );
};

export default RoutesContract;
