import { Box, Collapse, Grid } from "@mui/material";
import { FormGlue } from "src/hooks/useFormGlue";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import BuildItems from "src/components/Builders/Container/BuildItems";
import getStructure, {
  AdditionalInfoData,
  ItemsTypes,
  toSubmitData,
} from "./AdditionalInfoGetStructure";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Section from "src/components/Basic/Simple/Sections/Section";
import useFormContainer from "src/hooks/useFormContainer";
import useStyles from "src/components/Complex/Containers/ContractArticlesStyles";

type AdditionalInfoProps = {
  formGlue?: FormGlue<AdditionalInfoData>;
  invoicePlanInit?: AdditionalInfoData;
  type: ItemsTypes;
  useExpand?: boolean;
};

const ExpandMore = styled((props: any) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(90deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const AdditionalInfo = ({
  formGlue,
  invoicePlanInit,
  type,
  useExpand,
}: AdditionalInfoProps) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(!useExpand);
  const { formData, helperText, structure } =
    useFormContainer<AdditionalInfoData>({
      formGlue,
      getStructure,
      initialFormData: invoicePlanInit,
      toSubmitData,
    });

  const commonPropsBuildItem = {
    data: formData,
    helperText,
    disabled: !formGlue, // no formGlue if this is read-only
  };

  return (
    <Section
      label="Additional info"
      rightBoxArea={
        <Box component="div" sx={{ height: "50px" }}>
          {useExpand && (
            <ExpandMore
              expand={expanded}
              onClick={() => setExpanded(!expanded)}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <KeyboardArrowRightIcon />
            </ExpandMore>
          )}
        </Box>
      }
    >
      <Collapse
        className={classes.collapse}
        in={expanded}
        timeout="auto"
        unmountOnExit
      >
        <Grid
          container
          spacing={1}
          key={"contract-article-section"}
          alignItems="center"
          className={classes.contractArticleSection}
        >
          {BuildItems({
            items: structure.items.filter((item) => item.itemType === type),
            ...commonPropsBuildItem,
          })}
        </Grid>
      </Collapse>
    </Section>
  );
};

export default AdditionalInfo;
