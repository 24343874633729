import { ReportGetRequestQuery } from "src/accurasee-backend-types/app/report/report.types";
import { setFormDataDatePair } from "src/utils/date";
import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import getSelectOptions from "src/utils/getSelectOptions";

export const getStructure: UseFormContainerGetStructure<
  Partial<ReportGetRequestQuery>
> = ({ setFormData, t }) => {
  const structure: StructureContainer<Partial<ReportGetRequestQuery>> = {
    items: [
      {
        type: "date",
        dataName: "startDate",
        label: "From",
        setFormDataCustom: ({ data, value }) => {
          setFormDataDatePair({
            data,
            otherKey: "endDate",
            otherAction: "to_end_of_month",
            value,
            valueKey: "startDate",
            setFormData,
          });
        },
        sx: {
          "& .MuiInputBase-root": { borderRadius: "8px" },
        },
      },
      {
        type: "date",
        dataName: "endDate",
        label: "To",
        setFormDataCustom: ({ data, value }) => {
          setFormDataDatePair({
            data,
            otherKey: "startDate",
            otherAction: "to_start_of_month",
            value,
            valueKey: "endDate",
            setFormData,
          });
        },
        sx: {
          "& .MuiInputBase-root": { borderRadius: "8px" },
        },
      },
      {
        type: "selector",
        dataName: "resolution",
        label: "Resolution",
        options: getSelectOptions({
          data: [
            {
              label: "Week",
              value: "week",
            },
            {
              label: "Month",
              value: "month",
            },
            {
              label: "Quarter",
              value: "quarter",
            },
            {
              label: "Year",
              value: "year",
            },
          ].map((c) => ({ label: t(c.label), value: c.value })),
          isSort: false,
        }),
        setFormData,
        sx: { borderRadius: "8px" },
      },
    ],
  };

  return structure;
};
