import moment from "moment/moment";
import { ContractOfferPopulated } from "../../../accurasee-backend-types/app/contract_offer/contract_offer.types";
import { ReturnUser } from "src/accurasee-backend-types/app/user/user.types";
import { TemplateTagContractOfferItem } from "src/accurasee-backend-types/app/general/microsoft_drive/microsoft_drive.types";

const templateTagsContractOffer: TemplateTagContractOfferItem[] = [
  {
    key: "anvmail",
    description: "My email",
    property: "email",
    source: "user",
  },
  {
    key: "dagensdatum",
    description: "Today's date",
    property: () => moment().format("YYYY-MM-DD"),
    source: "callback",
  },
  {
    key: "egetforetag",
    description: "My company",
    property: "companyId.name",
    source: "contract_offer_populated",
  },
  {
    key: "foretag",
    description: "Customer company",
    property: "customerId.name",
    source: "contract_offer_populated",
  },
  {
    key: "kontaktfornamn",
    description: "Customer contact first name",
    property: "contactPersonId.firstName",
    source: "contract_offer_populated",
  },
  {
    key: "kontaktefternamn",
    description: "Customer contact last name",
    property: "contactPersonId.lastName",
    source: "contract_offer_populated",
  },
  {
    key: "ort",
    description: "Customer city",
    property: "customerId.city",
    source: "contract_offer_populated",
  },
  {
    key: "postadress",
    description: "Customer mailing address",
    property: "customerId.address1",
    source: "contract_offer_populated",
  },
  {
    key: "postnr",
    description: "Customer zipcode",
    property: "customerId.zipCode",
    source: "contract_offer_populated",
  },
  {
    key: "projekt",
    description: "Project name",
    property: "name",
    source: "contract_offer_populated",
  },
  {
    key: "projektanbsum",
    description: "Offer total price",
    property: "offerPrice",
    source: "contract_offer_populated",
  },
  {
    key: "projektarbetschef",
    description: "Project manager name",
    property: (offer?: ContractOfferPopulated) =>
      offer?.owners.map((o) => `${o.firstName} ${o.lastName}`).join(",") || "",
    source: "contract_offer_populated",
  },
  {
    key: "projektbestallare",
    description: "Customer company",
    property: "customerId.name",
    source: "contract_offer_populated",
  },
  {
    key: "projektbestkontaktperson",
    description: "Customer company, contact person first last",
    property: (offer?: ContractOfferPopulated) =>
      `${offer?.contractPersonId?.firstName} ${offer?.contractPersonId?.lastName}`,
    source: "contract_offer_populated",
  },
  {
    key: "projektnr",
    description: "Project id",
    property: "projectExternalId",
    source: "contract_offer_populated",
  },
  {
    key: "upprattadav",
    description: "Create by",
    property: (user?: ReturnUser) => `${user?.firstName} ${user?.lastName}`,
    source: "user",
  },
];

export default templateTagsContractOffer;
