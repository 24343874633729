import _c from "src/constants/Constants";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Tooltip,
  Typography,
} from "@mui/material";
import { AccessRolePermissionWithPermissions } from "src/accurasee-backend-types/app/access_role_permission/access_role_permission.types";
import { PERMISSION_TYPE, toSubmitData } from "./UserRolePermissionList";
import { useEffect } from "react";
import { useUpdatePermissionsMutation } from "src/redux/services/PermissionsService";
import { useUserRolePermission } from "../hooks/useUserRolePermission";
import * as React from "react";
import BadgeGreen from "src/components/Basic/Simple/Badges/BadgeGreen";
import BoxScrollWithShadow from "src/components/Basic/Simple/Box/BoxScrollWithShadow";
import ButtonCancel from "src/components/Basic/Simple/Buttons/ButtonCancel";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ButtonUpdate from "src/components/Basic/Simple/Buttons/ButtonUpdate";
import ModalWrapper from "src/components/Basic/Mixed/Modals/ModalWrapper";
import SectionWithButtons from "src/components/Basic/Mixed/Sections/SectionWithButtons";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import useStyles from "./UserRolePermissionModalWithFormStyles";
import useTranslation from "src/hooks/useTranslationWrapper";

interface PermissionModalProps {
  openModal: boolean;
  data: AccessRolePermissionWithPermissions[];
  roleName?: string;
  handleCloseModal: () => void;
}

const PermissionModal = ({
  openModal,
  data,
  roleName,
  handleCloseModal,
}: PermissionModalProps) => {
  const [t] = useTranslation();
  const classes = useStyles();

  function getToolTipText(collectionTarget: string) {
    if (_c.AUTH_RIGHT_CONTROLLED_BY_CONTRACT.includes(collectionTarget)) {
      return t("Controlled by contracts filter");
    } else {
      return t("Apply rights on all object or where user is owner");
    }
  }

  const [updatePermissions] = useUpdatePermissionsMutation();
  const {
    formData,
    modifiedPermissions,
    onChangeRolePermissions,
    setFormData,
    setModifiedPermissions,
  } = useUserRolePermission({ data });

  useEffect(() => {
    setFormData(data);
  }, [data]);

  const resetData = () => {
    setFormData(data);
    setModifiedPermissions([]);
  };

  const initialSubmitData = toSubmitData({
    data: data,
    filters: [],
  });

  const submitData = toSubmitData({
    data: formData,
    filters: modifiedPermissions,
  });

  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit({
    submitProps: {
      name: "User role's permissions",
      data: { update: submitData },
      apiMutations: { update: updatePermissions },
    },
    onSuccess: () => {
      resetData();
      handleCloseModal();
      window.location.reload();
    },
  });

  return (
    <ModalWrapper
      handleCloseModal={handleCloseModal}
      openModal={openModal}
      label={"EDIT PERMISSIONS"}
      labelBadge={roleName ? <BadgeGreen text={roleName} /> : undefined}
    >
      <Grid container sx={{ marginBottom: "var(--space-8px)" }}>
        <Grid item xs={5} />
        {PERMISSION_TYPE.map((type: any) => (
          <Grid key={`permission-${type.label}`} item xs={1}>
            <Typography className={classes.label}>{type.label}</Typography>
          </Grid>
        ))}
        <Grid item xs={2}>
          <Typography className={classes.label}>{t("Filters")}</Typography>
        </Grid>
      </Grid>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          await onFormSubmit({ action: "update" });
        }}
        ref={refForm}
      >
        <BoxScrollWithShadow>
          {formData.map((row, rowIndex: number) => {
            const filters = formData[rowIndex].filters;

            return (
              <Grid container key={row?.collectionTarget}>
                <Grid item xs={5}>
                  <Typography className={classes.collectionTarget}>
                    {t(
                      row?.collectionTarget
                        .split("_")
                        .join(" ")
                        .replace(/^./, (str: string) => str.toUpperCase()),
                    )}
                  </Typography>
                </Grid>
                {PERMISSION_TYPE.map((permission: any, index: number) => (
                  <Grid
                    id={`${row?.collectionTarget}-${permission.value}`}
                    key={`${row?.collectionTarget}-${permission.value}`}
                    item
                    xs={1}
                  >
                    <FormControlLabel
                      label=""
                      control={
                        <Checkbox
                          disabled={
                            !formData[rowIndex].permissions.updateRights
                          }
                          checked={
                            formData[rowIndex].collectionPermissions[
                              permission.value
                            ] || false
                          }
                          name={permission.value}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            event.persist();
                            const { name, checked } = event.target;
                            onChangeRolePermissions({
                              key: `collectionPermissions.${name}`,
                              rowIndex,
                              value: checked,
                            });
                          }}
                          sx={{ "&.Mui-checked": { color: "#1f5436" } }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                    />
                  </Grid>
                ))}
                <Grid item xs={2}>
                  {(row?.collectionTarget === "contracts" ||
                    row?.collectionTarget === "invoiceplan_templates" ||
                    row?.collectionTarget === "notifications" ||
                    _c.AUTH_RIGHT_CONTROLLED_BY_CONTRACT.includes(
                      row?.collectionTarget,
                    )) && (
                    <FormControl sx={{ minWidth: 80 }}>
                      <Tooltip
                        placement="top"
                        title={getToolTipText(row.collectionTarget)}
                      >
                        <Select
                          label="Filters"
                          displayEmpty
                          input={<OutlinedInput />}
                          sx={{
                            width: "100%",
                            "& .MuiSelect-select": {
                              padding: "var(--space-8px)",
                            },
                          }}
                          labelId="filters"
                          id="filters"
                          name="filters"
                          disabled={
                            _c.AUTH_RIGHT_CONTROLLED_BY_CONTRACT.includes(
                              row?.collectionTarget,
                            ) || !formData[rowIndex].permissions.updateRights
                          }
                          value={
                            filters && filters.length > 0 ? filters[0] : "all"
                          }
                          required
                          onChange={(event: SelectChangeEvent) => {
                            event.preventDefault();
                            const { value } = event.target;
                            onChangeRolePermissions({
                              key: "filters",
                              rowIndex,
                              value: [value],
                            });
                          }}
                          inputProps={{
                            "aria-label": "Without label",
                            "aria-hidden": "true",
                          }}
                        >
                          <MenuItem value="all">All</MenuItem>
                          <MenuItem value="own">Own</MenuItem>
                        </Select>
                      </Tooltip>
                    </FormControl>
                  )}
                </Grid>
              </Grid>
            );
          })}
        </BoxScrollWithShadow>
        <SectionWithButtons alignCenter>
          <ButtonGroup>
            <ButtonCancel
              id={"update-user-role-permission-cancel"}
              onClick={() => {
                resetData();
                handleCloseModal();
              }}
            />
            <ButtonUpdate
              id={"update-user-role-permission"}
              initialSubmitData={initialSubmitData}
              isSubmitting={isSubmitting}
              onSubmit={() => {
                refForm.current.requestSubmit();
              }}
              isValid={true}
              submitData={submitData}
            />
          </ButtonGroup>
        </SectionWithButtons>
      </form>
    </ModalWrapper>
  );
};
export default PermissionModal;
