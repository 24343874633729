import { backendApi } from "../api";
import { docPatchTag, listTag } from "./utils";
import {
  Budget,
  BudgetReturnWithPermission,
} from "../../accurasee-backend-types/app/budget/budget.types";

const CACHE_TAG = "Budgets";
export const articleApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getBudget: build.query<BudgetReturnWithPermission, any>({
      query: ({ contractId, ...params }) => ({
        url: `/budgets/${contractId}`,
        params,
      }),
      transformResponse: (response) => response.data,
      providesTags: listTag(CACHE_TAG),
    }),
    createBudget: build.mutation<any, Partial<Budget>>({
      query: (data) => ({ url: "/budgets", method: "POST", data }),
      invalidatesTags: listTag(CACHE_TAG),
    }),
    patchBudget: build.mutation<Budget, Partial<Budget> & { id: string }>({
      query: ({ id, ...data }) => ({
        url: `/budgets/${id}`,
        method: "PATCH",
        data,
      }),
      invalidatesTags: docPatchTag(CACHE_TAG),
    }),
  }),
});

export const {
  useCreateBudgetMutation,
  useGetBudgetQuery,
  usePatchBudgetMutation,
} = articleApi;

export default articleApi;
