/**
 *Created by Mikael Lindahl on 2024-10-22
 */
import Constants from "../../../../../constants/Constants";
import getTableFilterOwnerIds from "../../../../../utils/getTableFilterOwnerIds";
import { CustomerInvoice } from "../../../../../accurasee-backend-types/app/customerinvoice/customerinvoice.types";
import { Types } from "mongoose";
import { UserFormTableGetStructureOptional } from "../../../../../hooks/useFormTable";
import { useGetCompanyQuery } from "../../../../../redux/services/CompanyService";
import { useGetCompanyUsersQuery } from "../../../../../redux/services/UserService";
import { useLocation } from "react-router-dom";
import {
  CommonCallbackProps,
  StructureItemTable,
} from "../../../../Builders/Table/CommonBuilderTableTypes";
import {
  getCustomMinMaxFilterListOptions,
  getCustomMinMaxfilterOptions,
} from "../../../Containers/Filterhelper";

type GetStructureExtraProps = {};

type UseCustomerInvoicesTableStructureProps = {
  idSummaries: Types.ObjectId;
  includeProjectNumber?: boolean;
  redirectFoundationPath?: string;
};

const useCustomerInvoicesTableStructure = ({
  idSummaries,
  includeProjectNumber,
  redirectFoundationPath,
}: UseCustomerInvoicesTableStructureProps) => {
  const location = useLocation();

  const { data: companyQuery, isLoading: isLoadingCompany } =
    useGetCompanyQuery(undefined);
  const company = companyQuery?.company;

  const { data: companyUserQuery, isLoading: isLoadingCompanyUsers } =
    useGetCompanyUsersQuery(undefined);
  const users = companyUserQuery?.data;

  const getStructure: UserFormTableGetStructureOptional<
    Partial<CustomerInvoice>,
    GetStructureExtraProps
  > = (props) => {
    const t = props?.t ? props.t : (s: string) => s;

    return {
      items: [
        {
          dataName: "invoiceNumber",
          headerLabel: "#",
          type: ({ data, rowIndex }: CommonCallbackProps) => {
            return data[rowIndex]?._id === idSummaries ? "text" : "link";
          },
          redirectLink: (props: CommonCallbackProps) =>
            (redirectFoundationPath || location.pathname) +
            "/" +
            String(props.data[props.rowIndex]._id),
          sort: true,
        },
        {
          type: "text",
          dataName: "projectExternalId", // Allow to sort on number instead on name
          headerLabel: "Contract",
          sort: true,
          getValue: ({ data, rowIndex }: CommonCallbackProps) => {
            const invoice = data[rowIndex];
            if (
              invoice?.projectExternalId &&
              invoice?.projectName &&
              includeProjectNumber
            ) {
              const name =
                invoice?.projectExternalId + " - " + invoice?.projectName;
              const maxLength = Constants.MAX_WIDTH_NUMBER_OF_CHARS_IN_TABLE;
              return name.length > maxLength
                ? name.slice(0, maxLength) + "..."
                : name;
            } else return t("Not assigned to contract");
          },
        },
        {
          type: "link",
          redirectLink: (props: CommonCallbackProps) =>
            (redirectFoundationPath || location.pathname) +
            "/" +
            String(props.data[props.rowIndex]._id),
          dataName: "name",
          headerLabel: "Name",
          sort: true,
        },
        {
          type: "date",
          dataName: "dueDate",
          sort: true,
          headerLabel: "Due date",
          filter: true,
          filterType: "custom",
          customFilterListOptions: getCustomMinMaxFilterListOptions(
            t("Due date"),
          ),
          filterOptions: getCustomMinMaxfilterOptions(t("Due date"), "date"),
        },
        {
          type: "date",
          dataName: "invoiceDate",
          sort: true,
          headerLabel: "Invoice date",
          filter: true,
          filterType: "custom",
          customFilterListOptions: getCustomMinMaxFilterListOptions(
            t("Invoice date"),
          ),
          filterOptions: getCustomMinMaxfilterOptions(
            t("Invoice date"),
            "date",
          ),
        },
        {
          type: "text",
          dataName: "customerName",
          headerLabel: "Customer name",
          sort: true,
          getValue: ({ data, rowIndex }: CommonCallbackProps) => {
            const name = data[rowIndex]?.customerName || "";
            const maxLength = Constants.MAX_WIDTH_NUMBER_OF_CHARS_IN_TABLE;
            return name.length > maxLength
              ? name.slice(0, maxLength) + "..."
              : name;
          },
        },
        {
          type: "text",
          dataName: "ownerIds",
          headerLabel: "Contract owners",
          sort: true,
          getValue: ({ data, rowIndex }: CommonCallbackProps) => {
            const owners =
              data[rowIndex]?.ownerIds?.map((ownerId: Types.ObjectId) => {
                const owner = users?.find((e) => e._id === ownerId);
                return `${owner?.firstName} ${owner?.lastName}`;
              }) || [];

            return owners?.join(", ") || " ";
          },
          ...getTableFilterOwnerIds({ users, dataLabel: t("Contract owners") }),
        },
        {
          type: "badge",
          dataName: "status",
          headerLabel: "Status",
          sort: true,
          filter: true,
          filterOptions: {
            names: [
              "Exported",
              "Cancelled",
              "Invoiced",
              "Invoiced overdue",
              "Fully paid",
            ],
          },
          alignColumnContent: "center",
        },
        {
          type: "text_currency_integer",
          currencyCode: company?.currencyCode,
          dataName: "totalExcludingVAT",
          headerLabel: "Tot Excl VAT",
          sort: true,
          filter: true,
          filterType: "custom",
          customFilterListOptions: getCustomMinMaxFilterListOptions(
            t("Total excl. VAT"),
          ),
          filterOptions: getCustomMinMaxfilterOptions(
            t("Total excl. VAT"),
            "number",
          ),
          alignColumnContent: "end",
        },
        {
          type: "text_currency_integer",
          currencyCode: company?.currencyCode,
          dataName: "totalVAT",
          headerLabel: "VAT",
          sort: true,
          alignColumnContent: "end",
        },
        {
          type: "text_currency_integer",
          currencyCode: company?.currencyCode,
          dataName: "total",
          headerLabel: "Total",
          sort: true,
          filter: true,
          filterType: "custom",
          customFilterListOptions: getCustomMinMaxFilterListOptions(
            t("Total amount"),
          ),
          filterOptions: getCustomMinMaxfilterOptions(
            t("Total amount"),
            "number",
          ),
          alignColumnContent: "end",
        },
      ].map(
        (item, i) =>
          ({
            ...item,
            ...(i !== 0 && item.type !== "text_currency_integer"
              ? {
                  showCellWhen: (_props) =>
                    _props.data[_props.rowIndex]?._id !== idSummaries,
                }
              : {}),
          }) as StructureItemTable,
      ),
    };
  };

  return { getStructure, isLoadingCompany, isLoadingCompanyUsers };
};

export default useCustomerInvoicesTableStructure;
