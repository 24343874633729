/**
 *Created by Mikael Lindahl on 2023-12-28
 */
import { SectionCoreTProps } from "../../Mixed/Sections/SectionCore";
import SectionTransparentForTable from "./SectionTransparentForTable";

const SectionTransparentForTableWithToolbar = (
  props: Omit<SectionCoreTProps, "withTightTableHeading">,
) => {
  return (
    <SectionTransparentForTable
      transparent={true}
      withTightTableHeading={true}
      {...props}
    >
      {props.children}
    </SectionTransparentForTable>
  );
};

export default SectionTransparentForTableWithToolbar;
