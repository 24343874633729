import { Catalog } from "src/accurasee-backend-types/app/translation/translation.types";
import { axios } from "../general/Network";
import defaultTranslations from "../general/defaultTranslations";

export async function getTranslationCatalog(language: string) {
  try {
    const response = await axios.get("/translation/catalog/" + language);

    if (response.status !== 200) {
      return defaultTranslations;
    } else {
      return (await response.data.data) as Catalog;
    }
  } catch (error) {
    console.error("Error loading translations:", error);
    return defaultTranslations; // Fallback to default translations in case of failure
  }
}

export default getTranslationCatalog;
