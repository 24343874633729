import BuildItems from "src/components/Builders/Container/BuildItems";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import ButtonCancel from "src/components/Basic/Simple/Buttons/ButtonCancel";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ButtonSave from "src/components/Basic/Simple/Buttons/ButtonSave";
import ModalWrapper from "src/components/Basic/Mixed/Modals/ModalWrapper";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import Section from "src/components/Basic/Simple/Sections/Section";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import useFormContainer from "src/hooks/useFormContainer";
import useFormTable from "src/hooks/useFormTable";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import useTranslation from "src/hooks/useTranslationWrapper";
import YesNoDialog from "src/components/Basic/Simple/Dialogs/YesNoDialog";
import { ActiveContract, ContractsByUser } from "./TransferOwnershipsStructure";
import { Box, Grid } from "@mui/material";
import { ContractWithPermissions } from "src/accurasee-backend-types/app/contracts/contract.types";
import { MUIDataTableColumnDef } from "mui-datatables";
import { ReturnUser } from "src/accurasee-backend-types/app/user/user.types";
import { getPayload } from "./utils";
import { useMUIDataTableOptions } from "src/hooks/useMUIDataTableOptions";
import { usePatchContractsMutation } from "src/redux/services/ContractService";
import { useState } from "react";
import getTableStructure, {
  getStructure,
  GetStructureExtraProps,
  Mode,
} from "./EditModalStructure";

interface EditModalProps {
  users?: ReturnUser[];
  data?: ContractsByUser;
  openModal: boolean;
  setOpenModal: (v: boolean) => void;
}

const EditModalWithForm = ({
  users,
  data,
  openModal,
  setOpenModal,
}: EditModalProps) => {
  const [t] = useTranslation();

  const [mode, setMode] = useState<Mode>(Mode.basic);
  const [openYesNoDialog, setOpenYesNoDialog] = useState(false);

  const [patchContracts] = usePatchContractsMutation();

  // For Simple table
  const {
    formData,
    hasDataChanged,
    helperText,
    isFormValid,
    setHasTriedToSubmit,
    structure,
  } = useFormContainer<
    Partial<ContractWithPermissions>,
    GetStructureExtraProps
  >({
    getStructure,
    extraProps: {
      mode,
      users,
    },
    initialFormData: { ownerIds: data?.id ? [data.id] : [] },
  });
  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  // For Advanced table
  const {
    formData: formTableData,
    helperText: helperTextTable,
    isFormValid: isFormTableValid,
    setHasTriedToSubmit: setTableHasTriedToSubmit,
    structure: tableStructure,
  } = useFormTable<ActiveContract, GetStructureExtraProps>({
    getStructure: getTableStructure,
    extraProps: {
      mode,
      users,
    },
    initialFormData: data?.activeContracts,
  });
  const columns: MUIDataTableColumnDef[] = tableStructure.items.map((item) =>
    BuilderColumnOptionsSingle<ActiveContract>({
      data: formTableData,
      helperText: helperTextTable,
      item,
    }),
  );
  const { options } = useMUIDataTableOptions();

  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit({
    submitProps: {
      apiMutations: { update: patchContracts },
      data: {
        update: getPayload({ formData, formTableData, data, mode }),
      },
      name: "Contract information",
    },
    onSuccess: () => {
      if (mode === Mode.basic) {
        setHasTriedToSubmit(false);
      } else {
        setTableHasTriedToSubmit(false);
      }
      // close modal
      setOpenModal(false);
    },
  });

  return (
    <ModalWrapper
      handleCloseModal={() => setOpenModal(false)}
      openModal={openModal}
      label={"EDIT CONTRACT OWNERSHIP"}
    >
      <Section>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setOpenYesNoDialog(true);
          }}
          ref={refForm}
        >
          <ToggleButtonGroup
            sx={{
              display: "block",
              width: "240px",
              margin: "auto",
              marginBottom: "var(--space-24px)",
            }}
            value={mode}
            id="modal-toggle"
            exclusive
            onChange={(event: React.MouseEvent<HTMLElement>, value: Mode) =>
              setMode(value)
            }
          >
            <ToggleButton
              key="toggle-basic"
              sx={{ padding: "8px", lineHeight: "22px", width: "50%" }}
              value={Mode.basic}
              name="Basic"
            >
              {t("Simple")}
            </ToggleButton>
            <ToggleButton
              key="toggle-advance"
              sx={{ padding: "8px", lineHeight: "22px", width: "50%" }}
              value={Mode.advanced}
              name="Advanced"
            >
              {t("Advanced")}
            </ToggleButton>
          </ToggleButtonGroup>
          <MuiDataTableWrapper
            title={"Data table"}
            data={formTableData || []}
            columns={columns}
            options={options}
          />
          {mode === Mode.basic && (
            <Grid
              sx={{ marginTop: "var(--space-8px)" }}
              container
              rowSpacing={2}
              columnSpacing={1}
            >
              {BuildItems({
                items: structure.items,
                ...commonPropsBuildItem,
              })}
            </Grid>
          )}
        </form>
      </Section>
      <SectionWithButtonContainer buttonCenter>
        <ButtonGroup>
          <ButtonCancel onClick={() => setOpenModal(false)} />
          <ButtonSave
            initialSubmitData={
              mode === Mode.basic
                ? { ownerIds: data?.id ? [data.id] : [] }
                : data?.activeContracts
            }
            isSubmitting={isSubmitting}
            isValid={
              mode === Mode.basic
                ? isFormValid && hasDataChanged
                : isFormTableValid
            }
            onSubmit={() => {
              refForm.current.requestSubmit();
              if (mode === Mode.basic) {
                setHasTriedToSubmit(true);
              } else {
                setTableHasTriedToSubmit(true);
              }
            }}
            submitData={formTableData}
          />
        </ButtonGroup>
        <YesNoDialog
          open={openYesNoDialog}
          onYes={async () => {
            setOpenYesNoDialog(false);
            await onFormSubmit();
          }}
          onNo={() => setOpenYesNoDialog(false)}
        >
          <Box sx={{ fontWeight: 500 }}>
            {t("Are you sure you want to transfer the contract ownerships?")}
          </Box>
        </YesNoDialog>
      </SectionWithButtonContainer>
    </ModalWrapper>
  );
};

export default EditModalWithForm;
