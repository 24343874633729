import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import ButtonCancel from "src/components/Basic/Simple/Buttons/ButtonCancel";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ButtonSubmitCore from "src/components/Basic/Mixed/Buttons/ButtonSubmitCore";
import ModalWrapper from "src/components/Basic/Mixed/Modals/ModalWrapper";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import Section from "src/components/Basic/Simple/Sections/Section";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import SectionWithButtonContainer from "src/components/Basic/Mixed/Sections/SectionWithButtonContainer";
import clone from "src/utils/clone";
import useInvoiceStateChange from "src/hooks/useInvoiceStateChange";
import useFormTable from "src/hooks/useFormTable";
import useOnFormSubmitCustom from "src/hooks/useOnFormSubmitCustom";
import useTranslation from "src/hooks/useTranslationWrapper";
import { Box, Grid, Typography } from "@mui/material";
import { MUIDataTableColumnDef } from "mui-datatables";
import { getEstimateRows, isEstimateRows } from "src/utils/invoiceRows";
import { listToDic } from "src/utils/transform";
import { useMUIDataTableOptions } from "src/hooks/useMUIDataTableOptions";
import {
  getStructure,
  GetStructureExtraProps,
} from "./ApprovePlannedInvoicesModalGetStructure";
import {
  PlannedInvoiceRow,
  PlannedInvoiceWithPermissions,
} from "src/accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";
import useGetArticles from "../../../hooks/useGetArticles";

type SubmitAction = "export" | "save_and_export" | "save";

type ApproveInvoicesModalTProps = {
  handleCloseModal: () => void;
  openModal: boolean;
  plannedInvoice?: PlannedInvoiceWithPermissions;
};
const ApprovePlannedInvoicesModalWithForm = (
  props: ApproveInvoicesModalTProps,
) => {
  const [t] = useTranslation();
  const initialData = getEstimateRows(props.plannedInvoice?.invoiceRows);

  const { articlesWithContractArticleData, isLoading: isLoadingArticles } =
    useGetArticles(props?.plannedInvoice?.contractId);

  const { formData, helperText, isFormValid, setHasTriedToSubmit, structure } =
    useFormTable<PlannedInvoiceRow, GetStructureExtraProps>({
      extraProps: { articles: articlesWithContractArticleData },
      initialFormData: initialData,
      getStructure,
    });

  const { onInvoiceStateChange, isInvoiceStateChangeLoading } =
    useInvoiceStateChange({
      selectedPlannedInvoiceIds: props.plannedInvoice?._id
        ? [props.plannedInvoice?._id]
        : [],
    });

  const onSubmit: (
    action: SubmitAction,
  ) => Promise<{ status: "success" }> = async (action) => {
    if (props.plannedInvoice) {
      let rows = clone(props.plannedInvoice?.invoiceRows);

      // Remove rows with quantity or prices set to zero
      // which aren't text rows
      rows = rows.filter(
        (r) => (r.quantity !== 0 && r.pricePerUnit !== 0) || r.textRow,
      );

      const formDataDic = listToDic(formData, (v) => String(v._id));
      for (let i = 0; i < rows?.length; i++) {
        const id = String(rows[i]._id);
        if (id && formDataDic[id]) {
          rows[i] = formDataDic[id];
        }
      }

      const newPlannedInvoice = {
        _id: props.plannedInvoice._id,
        invoiceRows: rows,
      };

      await onInvoiceStateChange({
        plannedInvoice: newPlannedInvoice,
        onlySave: action === "save",
      });
    }

    // props.onChange({ data: formData, action });
    props.handleCloseModal();
    return { status: "success" };
  };

  const { isSubmitting: isSubmittingSave, onFormSubmit: onFormSubmitSave } =
    useOnFormSubmitCustom({ onSubmit: async () => onSubmit("save") });

  const {
    isSubmitting: isSubmittingSaveAndExport,
    onFormSubmit: onFormSubmitSaveAndExport,
    refForm,
  } = useOnFormSubmitCustom({
    onSubmit: async () => onSubmit("save_and_export"),
  });

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle<PlannedInvoiceRow>({
      data: formData,
      helperText,
      item,
    }),
  );

  const { options } = useMUIDataTableOptions({
    pagination: formData.length >= 10,
  });

  const isEstimate = isEstimateRows(props.plannedInvoice?.invoiceRows);
  const label = isEstimate
    ? "There are rows with values that needs your attention"
    : "Approve Planned invoice";
  const subLabel = isEstimate
    ? "Before approving the invoice, you need to edit the estimates. Rows with quantity or price set to zero will be deleted"
    : "Send invoice to accounting";

  let action: SubmitAction = "save";
  return (
    <ModalWrapper
      openModal={props.openModal}
      handleCloseModal={() =>
        !isInvoiceStateChangeLoading && props.handleCloseModal()
      }
      label={label}
      subLabel={subLabel}
    >
      <SectionContainer>
        <Section isLoading={isLoadingArticles}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              color: "var(--green-primary)",
              marginLeft: "var(--space-8px)",
            }}
          >
            <Typography sx={{ fontWeight: "bold" }}>
              {t("Contract name")}
            </Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              {t("Customer name")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginLeft: "var(--space-8px)",
            }}
          >
            <Typography>{props.plannedInvoice?.contractName || "-"}</Typography>
            <Typography>{props.plannedInvoice?.customerName || "-"}</Typography>
          </Box>
          {isEstimate ? (
            <Grid item xs={12}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (action === "save") {
                    onFormSubmitSave().catch((err) => console.log(err));
                  } else if (action === "save_and_export") {
                    onFormSubmitSaveAndExport().catch((err) =>
                      console.log(err),
                    );
                  }
                }}
                ref={refForm}
              >
                <MuiDataTableWrapper
                  title={"Data table"}
                  data={formData || []}
                  columns={columns}
                  options={options}
                />
              </form>
            </Grid>
          ) : (
            <></>
          )}
        </Section>
      </SectionContainer>
      <SectionWithButtonContainer
        buttonCenter={!isEstimate}
        sx={{ padding: "0 var(--space-32px)" }}
      >
        <ButtonGroup>
          <ButtonCancel
            disabled={isInvoiceStateChangeLoading}
            onClick={props.handleCloseModal}
          />
          {isEstimate ? (
            [
              <ButtonSubmitCore
                // disabled={isLoading}
                key={"save"}
                isSubmitting={isSubmittingSave}
                initialSubmitData={initialData}
                isValid={isFormValid}
                label={"save without sending"}
                onSubmit={async () => {
                  action = "save";
                  refForm.current.requestSubmit();
                  setHasTriedToSubmit(true);
                }}
                permissions={props?.plannedInvoice?.permissions}
                submitData={formData}
              />,
              <ButtonSubmitCore
                // disabled={isLoading}
                key={"save and send"}
                initialSubmitData={initialData}
                isSubmitting={isSubmittingSaveAndExport}
                isValid={isFormValid}
                label={"save & send"}
                onSubmit={async () => {
                  action = "save_and_export";
                  refForm.current.requestSubmit();
                  setHasTriedToSubmit(true);
                }}
                permissions={props?.plannedInvoice?.permissions}
                submitData={formData}
              />,
            ]
          ) : (
            <ButtonSubmitCore
              isSubmitting={isSubmittingSaveAndExport}
              label={"send"}
              onSubmit={async () => {
                await onFormSubmitSaveAndExport();
                setHasTriedToSubmit(true);
              }}
              permissions={props?.plannedInvoice?.permissions}
            />
          )}
        </ButtonGroup>
      </SectionWithButtonContainer>
    </ModalWrapper>
  );
};
export default ApprovePlannedInvoicesModalWithForm;
