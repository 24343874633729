/**
 *Created by Mikael Lindahl on 2023-05-24
 */

import { FilterToParams } from "../../../../hooks/useMUIDataTableFilterStates";
import { GreaterAndOrLessThanFilter } from "src/accurasee-backend-types/app/general/controller.utils.types";
import _c from "src/constants/Constants";
export const defaultStatusMapper = {
  "Fully paid": "fullypaid",
  "Invoiced overdue": "invoicedoverdue",
  "Partially invoiced": "partiallyinvoiced",
  "Ready to invoice": "ready_to_invoice",
  "Requires input": "requires_input",
  "Unpaid overdue": "unpaidoverdue",
  Archived: "archived",
  Cancelled: "cancelled",
  Exported: "exported",
  Finished: "finished",
  Invoiced: "invoiced",
  Late: "late",
  Locked: "locked",
  Ongoing: "ongoing",
  Unpaid: "unpaid",
  Upcoming: "upcoming",
  Draft: "draft",
  Sent: "sent",
  Approved: "approved",
  Declined: "declined",
  Resent: "resent",
};

export const filterTranslatorFunction = (
  data: any,
  key: string,
  statusMapper: Record<string, string> = defaultStatusMapper,
) => {
  let params: Record<string, GreaterAndOrLessThanFilter | any> = {};
  if (key === "status" || key === "customerStatus") {
    params[key] = data.map((status: string) => statusMapper[status] || status);
  } else if (_c.FILTER_NUMBER_FIELDS.includes(key)) {
    const filter: GreaterAndOrLessThanFilter = {
      greaterAndOrLessThanFilter: [
        {
          operator: ">=",
          value: data[0],
          dataType: "number",
        },
        {
          operator: "<=",
          value: data[1],
          dataType: "number",
        },
      ],
    };
    params[key] = filter;
  } else if (_c.FILTER_DATE_FIELDS.includes(key) && Array.isArray(data)) {
    params[key] = {
      greaterAndOrLessThanFilter: [
        {
          operator: ">=",
          value: data[0],
          dataType: "date",
        },
        {
          operator: "<=",
          value: data[1],
          dataType: "date",
        },
      ],
    };
  } else {
    params[key] = data;
  }
  return params;
};
export const filterToParams: FilterToParams = (filter) => {
  let params: Record<string, GreaterAndOrLessThanFilter | any> = {};

  for (let key in filter) {
    const data = filter[key];
    params = { ...params, ...filterTranslatorFunction(data, key) };
  }
  return params;
};
