import CostsTable from "src/screens/App/Contracts/[contractId]/Costs/components/CostsTable";
import Page from "src/components/Basic/Mixed/Pages/Page";
import { escape } from "src/utils/translate";
import { useGetContractQuery } from "src/redux/services/ContractService";
import { useParams } from "react-router-dom";

const ContractCosts = () => {
  const { contractId } = useParams<{ contractId: string }>();

  const { data: contract, isLoading: isLoadingContract } =
    useGetContractQuery(contractId);

  return (
    <Page
      label={"Costs"}
      breadcrumbs={[
        { label: "Contracts", link: "/app/contracts" },
        {
          label: `Contract${escape(` ${contract?.projectExternalId || ""}`)}`,
          link: `/app/contracts/${contractId}`,
        },
        {
          label: "Costs",
        },
      ]}
      isLoading={isLoadingContract}
    >
      <CostsTable />
    </Page>
  );
};

export default ContractCosts;
