/**
 *Created by Mikael Lindahl on 2024-10-29
 */
import {
  InvoicePlanRow,
  InvoicePlanRowExtended,
} from "../../app/invoiceplan/invoiceplan.types";
import calculateDependable from "./calculateDependable";
import { RETAINED_FUNDS_ROW_RATIO } from "../constants/general";

export type InvoicePlanRowWithRetainedFunds = InvoicePlanRow & {
  tmpRetainedFunds?: number;
  tmpTotalSum?: number;
  tmpRetainedFundsAccumulator?: number;
};

/**
 * Calculate retained funds for each row in invoice plan and add total sum, total estimate VAT, retained funds and
 * accumulated retained funds to each row.
 *
 *  1. sum = exclude+retained
 *  2. retained = sum*0.1
 *  =>
 *  retained = (exclude + retained)*0.1
 *  retained*0.9 = exclude
 *  retained = exclude/0.9
 *
 * @param invoiceRows
 * @param currentRowIndex
 * @param value
 * @param totalPrice
 */
const addRetainedFundsDataToInvoiceRows = ({
  invoiceRows,
  currentRowIndex,
  value,
}: {
  invoiceRows: InvoicePlanRowExtended[];
  currentRowIndex?: number;
  value?: number;
}) => {
  const lastRow = invoiceRows.length > 0 ? invoiceRows.slice(-1)[0] : undefined;

  let _totalFunds = lastRow?.isRetainedFunds
    ? lastRow.totalExcludingVAT
    : undefined;

  if (!_totalFunds) {
    return invoiceRows;
  }

  let totalFunds = _totalFunds as number;

  // let fundsLeft = totalPrice * _c.RETAINED_FUNDS_TOTAL_RATIO;

  let accumulator = 0;

  invoiceRows.forEach((row, index) => {
    // Do nothing to the last row - Retained funds row
    if (row.isRetainedFunds) {
      row.tmpRetainedFunds = 0;
      row.tmpRetainedFundsAccumulator = accumulator;
      row.tmpTotalSum = row.totalExcludingVAT;

      return;
    }

    // Be greedy and infer retained funds from the total excluding VAT
    // Will later be adjusted if there are not enough funds left
    let currentRetainedFunds =
      row.tmpTotalSum !== undefined
        ? Math.round(row.tmpTotalSum * RETAINED_FUNDS_ROW_RATIO)
        : Math.round(
            (row.totalExcludingVAT / (1 - RETAINED_FUNDS_ROW_RATIO)) *
              RETAINED_FUNDS_ROW_RATIO,
          );
    let currentTotalExcludingVAT = 0;
    let currentTotalSum = 0;

    // If current row is the row we are updating we can to calculate the retained funds
    // directly for this row
    if (value !== undefined && index === currentRowIndex) {
      currentRetainedFunds = Math.round(value * RETAINED_FUNDS_ROW_RATIO);
    }

    let fundsLeft = totalFunds - accumulator;
    // Adjust according to funds left
    if (fundsLeft > 0) {
      currentRetainedFunds =
        fundsLeft < currentRetainedFunds ? fundsLeft : currentRetainedFunds;
      accumulator = currentRetainedFunds + accumulator;
    } else {
      currentRetainedFunds = 0;
    }

    // Update the total excluding VAT for the row using the retained funds
    if (value !== undefined && index === currentRowIndex) {
      currentTotalExcludingVAT = value - currentRetainedFunds;
    } else if (
      row.tmpRetainedFunds !== undefined &&
      row.tmpTotalSum !== undefined
    ) {
      currentTotalExcludingVAT = row?.tmpTotalSum - currentRetainedFunds;
    } else {
      currentTotalExcludingVAT = row.totalExcludingVAT;
    }

    // Update the total sum for the row using the retained funds
    if (value !== undefined && index === currentRowIndex) {
      currentTotalSum = value;
    } else if (
      row.tmpRetainedFunds !== undefined &&
      row.tmpTotalSum !== undefined
    ) {
      currentTotalSum = row?.tmpTotalSum;
    } else {
      currentTotalSum = currentTotalExcludingVAT + currentRetainedFunds;
    }

    row.tmpRetainedFunds = currentRetainedFunds;
    row.tmpRetainedFundsAccumulator = accumulator;
    row.tmpTotalSum = currentTotalSum;
    row.pricePerUnit = currentTotalExcludingVAT;

    const dependable = calculateDependable({ data: row });
    row.total = dependable.total as number;
    row.totalExcludingVAT = dependable.totalExcludingVAT as number;
  });
};

export default addRetainedFundsDataToInvoiceRows;
